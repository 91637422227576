import React from "react";
export const tableinfos = [
	{
		title: "file",
		key: "name",
		render: (object = {}) => (
			<a href={object.path} target="_blank">
				download
			</a>
		),
	},

	{
		title: "Created At",
		key: "created at",
		render: (object = {}) => (
			<p>{new Date(object.createdAt).toLocaleString()}</p>
		),
	},
];
