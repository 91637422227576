import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { notification } from "antd";
import { REINIT_MSG } from "../../redux/actions/actionTypes";

const Notifications = (props) => {
	const dispatch = useDispatch();
	const { message, type } = useSelector((state) => state.Notification);
	useEffect(() => {
		if (!type) {
			return;
		}

		notification[type]({
			message,
		});
		dispatch({ type: REINIT_MSG });
	}, [message]);

	return <div></div>;
};

export default Notifications;
