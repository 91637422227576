import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { routerReducer, routerMiddleware } from "react-router-redux";
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import rootReducers from "./reducer/index";
// import rootSaga from "./sagas";

const createHistory = require("history").createBrowserHistory;
const history = createHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, sagaMiddleware, routeMiddleware];

const composeEnhancers =
	process.env.NODE_ENV === "development"
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		: null || compose;
const rootReducer = combineReducers({
	...rootReducers,
	router: routerReducer,
});
const store = createStore(
	rootReducer,
	composeEnhancers(applyMiddleware(...middlewares)),
);
// sagaMiddleware.run(rootSaga);
export { store, history };
