import axios from "../axios/axios-orders";
import * as actionTypes from "./actionTypes";

const fetchConfigsStart = () => ({
	type: actionTypes.FETCH_CONFIGS_START,
});

const fetchConfigsSuccess = (data, total) => ({
	type: actionTypes.FETCH_CONFIGS_SUCCESS,
	configs: data,
	total: total,
});

const fetchConfigsFail = () => ({
	type: actionTypes.FETCH_CONFIGS_FAIL,
});

export const fetchConfigs = () => {
	return (dispatch) => {
		dispatch(fetchConfigsStart());

		axios
			.get("configs")
			.then((res) => {
				if (res.status === 200 || res.status === 201) {
					dispatch(fetchConfigsSuccess(res.data.data, res.data.total));
				} else dispatch(fetchConfigsFail());
			})
			.catch((err) => {
				dispatch(fetchConfigsFail());
			});
	};
};

const updateConfigsStart = () => ({
	type: actionTypes.UPDATE_CONFIGS_START,
});

const updateConfigsSuccess = (data) => ({
	type: actionTypes.UPDATE_CONFIGS_SUCCESS,
	configs: data,
});

const updateConfigsFail = () => ({
	type: actionTypes.UPDATE_CONFIGS_FAIL,
});

export const updateConfigs = (data) => {
	return (dispatch) => {
		dispatch(updateConfigsStart());

		axios
			.patch("configs", data)
			.then((res) => {
				if (res.status === 200) {
					dispatch(updateConfigsSuccess(res.data.data, res.data.total));
					dispatch(updateConfigsFail());
					dispatch({
						type: actionTypes.SUCCESS_MSG,
						message: "update successfully",
					});
				} else {
					dispatch({
						type: actionTypes.ERORR_MSG,
						message: "update fail",
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: actionTypes.ERORR_MSG,
					message: "update fail",
				});
				dispatch(updateConfigsFail());
			});
	};
};
