import React from "react";
import { store, history } from "./redux/store";
import PublicRoutes from "./PublicRouters";
import { Provider } from "react-redux";
import Notifications from "./components/Notification";

const DashApp = () => (
	<Provider store={store}>
		<Notifications />
		<PublicRoutes history={history} />
	</Provider>
);

export default DashApp;
