import axios from "axios";

const instance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		"Access-Control-Allow-Origin": "*",
		"Content-Type": "multipart/form-data",
	},
});
instance.interceptors.request.use(async (config) => {
	const token = await localStorage.getItem("admin_token");
	config.headers["Authorization"] = `Bearer ${token}`;
	return config;
});

export default instance;
