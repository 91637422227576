import axios from "../axios/axios-orders";
import * as actionTypes from "./actionTypes";

const updateAddressStart = () => ({
	type: actionTypes.UPDATE_ADDRESS_START,
});

const updateAddressSuccess = (data, total) => ({
	type: actionTypes.UPDATE_ADDRESS_SUCCESS,
	address: data,
});

const updateAddressFail = () => ({
	type: actionTypes.UPDATE_ADDRESS_FAIL,
});

export const updateAddress = (addressId, userId, data, callback) => {
	return (dispatch) => {
		dispatch(updateAddressStart());

		axios
			.put(`admin/addresses/${addressId}/${userId}`, data)
			.then((res) => {
				if (res.status === 200 || res.status === 201) {
					dispatch(updateAddressSuccess(res.data.data));
					callback && callback();
				} else {
					dispatch(updateAddressFail());
				}
			})
			.catch((err) => {
				dispatch(updateAddressFail());
			});
	};
};

const createAddressStart = () => ({
	type: actionTypes.CREATE_ADDRESS_START,
});

const createAddressSuccess = (data, total) => ({
	type: actionTypes.CREATE_ADDRESS_SUCCESS,
	address: data,
});

const createAddressFail = () => ({
	type: actionTypes.CREATE_ADDRESS_FAIL,
});

export const createAddress = (userId, data, callback) => {
	return (dispatch) => {
		dispatch(createAddressStart());

		axios
			.post(`admin/addresses/${userId}`, data)
			.then((res) => {
				if (res.status === 200 || res.status === 201) {
					dispatch(createAddressSuccess(res.data.data));
					callback && callback();
				} else {
					dispatch(createAddressFail());
				}
			})
			.catch((err) => {
				dispatch(createAddressFail());
			});
	};
};
