import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
	configs: {},
	loading: false,
};

const configsReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_CONFIGS_START:
			return updateObject(state, { loading: true });
		case actionTypes.FETCH_CONFIGS_SUCCESS:
			return updateObject(state, {
				loading: false,
				configs: action.configs,
			});
		case actionTypes.FETCH_CONFIGS_FAIL:
			return updateObject(state, { loading: false });

		case actionTypes.UPDATE_CONFIGS_START:
			return updateObject(state, { loading: true });
		case actionTypes.UPDATE_CONFIGS_SUCCESS:
			return updateObject(state, {
				loading: false,
				configs: action.configs,
			});
		case actionTypes.UPDATE_CONFIGS_FAIL:
			return updateObject(state, { loading: false });

		default:
			return state;
	}
};

export default configsReducer;
