import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
	products: [],
	product: {},
	total: 0,
	loading: false,
	options: {},
	updatedProducts: [],
	totalUpdatedProducts: 0,
};

const updateData = (state, updatedProduct) => {
	var products = [];

	state.products.forEach((product) => {
		if (updatedProduct._id !== product._id) {
			products.push(product);
		} else if (!updatedProduct.is_deleted) {
			products.push(updatedProduct);
		}
	});

	return updateObject(state, {
		products: products,
		product: updatedProduct,
		loading: false,
		total: state.total - 1,
	});
};

const productsReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_PRODUCTS_START:
			return updateObject(state, { loading: true });
		case actionTypes.FETCH_PRODUCTS_SUCCESS:
			return updateObject(state, {
				loading: false,
				products: action.products,
				total: action.total,
			});
		case actionTypes.FETCH_PRODUCTS_FAIL:
			return updateObject(state, { loading: false });
		case actionTypes.REINIT_PRODUCTS:
			return updateObject(state, initialState);
		case actionTypes.CREATE_PRODUCTS_START:
			return updateObject(state, { loading: true });
		case actionTypes.CREATE_PRODUCTS_SUCCESS:
			return updateObject(state, {
				product: action.product,
				products: [action.product, ...state.products],
				loading: false,
			});
		case actionTypes.CREATE_PRODUCTS_FAIL:
			return updateObject(state, { loading: false });
		case actionTypes.FETCH_PRODUCT_START:
			return updateObject(state, { loading: true });
		case actionTypes.FETCH_PRODUCT_SUCCESS:
			return updateObject(state, {
				loading: false,
				product: action.product,
			});
		case actionTypes.FETCH_PRODUCT_FAIL:
			return updateObject(state, { loading: false });
		case actionTypes.UPDATE_PRODUCT_START:
			return updateObject(state, { loading: true });
		case actionTypes.UPDATE_PRODUCT_SUCCESS:
			return updateData(state, action.product);
		case actionTypes.UPDATE_PRODUCT_FAIL:
			return updateObject(state, { loading: false });

		case actionTypes.DELETE_PRODUCT_MEDIA_START:
			return updateObject(state, { loading: true });
		case actionTypes.DELETE_PRODUCT_MEDIA_SUCCESS:
			return updateObject(state, {
				loading: false,
			});
		case actionTypes.DELETE_PRODUCT_MEDIA_FAIL:
			return updateObject(state, { loading: false });
		case actionTypes.FETCH_PRODUCT_OPTIONS_START:
			return updateObject(state, { loading: true });
		case actionTypes.FETCH_PRODUCT_OPTIONS_SUCCESS:
			return updateObject(state, {
				loading: false,
				options: action.options,
			});
		case actionTypes.FETCH_PRODUCT_OPTIONS_FAIL:
			return updateObject(state, { loading: false });

		case actionTypes.SORT_PRODUCT_START:
			return updateObject(state, { loading: true });
		case actionTypes.SORT_PRODUCT_SUCCESS:
			return updateObject(state, { loading: false });

		case actionTypes.SORT_PRODUCT_FAIL:
			return updateObject(state, { loading: false });

		case actionTypes.UPLOAD_PRODUCTS_START:
			return updateObject(state, { loading: true });
		case actionTypes.UPLOAD_PRODUCTS_SUCCESS:
			return updateObject(state, { loading: false });
		case actionTypes.UPLOAD_PRODUCTS_FAIL:
			return updateObject(state, { loading: false });

		case actionTypes.CHANGE_PRODUCTS_PRICES_START:
			return updateObject(state, { loading: true });
		case actionTypes.CHANGE_PRODUCTS_PRICES_SUCCESS:
			return updateObject(state, { loading: false });
		case actionTypes.CHANGE_PRODUCTS_PRICES_FAIL:
			return updateObject(state, { loading: false });

		case actionTypes.FETCH_UPDATED_PRODUCTS_PRODUCTS_PRICES_START:
			return updateObject(state, { loading: true });

		case actionTypes.FETCH_UPDATED_PRODUCTS_PRODUCTS_PRICES_SUCCESS:
			return updateObject(state, {
				loading: false,
				updatedProducts: action.data,
				totalUpdatedProducts: action.total,
			});

		case actionTypes.FETCH_UPDATED_PRODUCTS_PRODUCTS_PRICES_FAIL:
			return updateObject(state, { loading: false });

		default:
			return state;
	}
};

export default productsReducer;
