import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import {
	sortableContainer,
	sortableElement,
	arrayMove,
} from "react-sortable-hoc";

import { Table } from "antd";
import { useDispatch } from "react-redux";

const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

const LabibaTable = (props) => {
	const [data, setData] = useState([]);
	const dispatch = useDispatch();

	useEffect(() => {
		if (props.data) {
			const newData = props.data.sort((a, b) => a.sort - b.sort);
			setData(newData);
		}
	}, [props.data]);

	const onChange = (pagination, filters, sorter) => {
		// if (sorter && sorter.columnKey && sorter.order) {
		// 	if (sorter.order === "ascend") {
		// 		// getSortAsc(sorter.columnKey);
		// 	} else {
		// 		// getSortDesc(sorter.columnKey);
		// 	}
		// }
		let filter = {};

		if (pagination) {
			filter["page"] = pagination.current;
		}

		dispatch(props.onFetchData(filter));
	};

	const onSortEnd = ({ oldIndex, newIndex }) => {
		if (oldIndex !== newIndex) {
			const newData = arrayMove([].concat(data), oldIndex, newIndex).filter(
				(el) => !!el,
			);

			let sortItems = {};
			if (props.onSort) {
				for (let index = 0; index < newData.length; index++) {
					const ele = newData[index];
					sortItems[ele._id] = index + 1;
					newData[index].sort = index + 1;
				}
				props.onSort(sortItems, newData);
			}

			setData(newData);
		}
	};

	const DraggableContainer = (props) => (
		<SortableContainer
			useDragHandle
			helperClass="row-dragging"
			onSortEnd={onSortEnd}
			{...props}
		/>
	);

	const DraggableBodyRow = ({ className, style, ...restProps }) => {
		const index = data.findIndex((x) => x.sort === restProps["data-row-key"]);
		return <SortableItem index={index} {...restProps} />;
	};

	const options = {
		pagination: !props.hidePagination
			? {
					pageSize: 40,
					total: props.total,
					showSizeChanger: false,
			  }
			: false,
		// rowHeight: () => 54,
	};

	return (
		<Table
			rowKey={(record) => record.sort || record._id}
			onChange={onChange}
			columns={props.columns}
			{...options}
			dataSource={data}
			scroll={{ y: 500 }}
			components={{
				body: {
					wrapper: DraggableContainer,
					row: DraggableBodyRow,
				},
			}}
		/>
	);
};

export default LabibaTable;
