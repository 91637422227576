import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
	roles: [],
	role: {},
	total: 0,
	loading: false,
	options: {},
};

const updateData = (state, updatedRole) => {
	var roles = [];

	state.roles.forEach((role) => {
		if (updatedRole._id !== role._id) {
			roles.push(role);
		} else if (!updatedRole.is_deleted) {
			roles.push(updatedRole);
		}
	});

	return updateObject(state, {
		roles: roles,
		role: updatedRole,
		loading: false,
		total: state.total - 1,
	});
};

const rolesReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_ROLES_START:
			return updateObject(state, { loading: true });
		case actionTypes.FETCH_ROLES_SUCCESS:
			return updateObject(state, {
				loading: false,
				roles: action.roles,
				total: action.total,
			});
		case actionTypes.FETCH_ROLES_FAIL:
			return updateObject(state, { loading: false });
		case actionTypes.REINIT_ROLES:
			return updateObject(state, initialState);

		case actionTypes.CREATE_ROLE_START:
			return updateObject(state, { loading: true });
		case actionTypes.CREATE_ROLE_SUCCESS:
			return updateObject(state, {
				role: action.role,
				roles: [action.role, ...state.roles],
				loading: false,
			});
		case actionTypes.CREATE_ROLE_FAIL:
			return updateObject(state, { loading: false });

		case actionTypes.UPDATE_ROLE_START:
			return updateObject(state, { loading: true });
		case actionTypes.UPDATE_ROLE_SUCCESS:
			return updateData(state, action.role);
		case actionTypes.UPDATE_ROLE_FAIL:
			return updateObject(state, { loading: false });

		default:
			return state;
	}
};

export default rolesReducer;
