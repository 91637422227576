import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
	categories: [],
	category: {},
	total: 0,
	loading: false,
	options: {},
};

const updateData = (state, updatedcategory) => {
	var categories = [];

	state.categories.forEach((category) => {
		if (updatedcategory._id !== category._id) {
			categories.push(category);
		} else if (!updatedcategory.is_deleted) {
			categories.push(updatedcategory);
		}
	});

	return updateObject(state, {
		categories: categories,
		category: updatedcategory,
		loading: false,
		total: state.total - 1,
	});
};

const updateProduct = (state, product) => {
	if (state.category?._id) {
		const products = state.category.products.map((prod) => {
			if (prod._id === product._id) {
				return product;
			}
			return prod;
		});

		state.category.products = products;
	}

	return updateObject(state, {
		category: state.category,
		loading: false,
	});
};

const categoriesReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.REINIT_CATEGORIES:
			return updateObject(state, initialState);
		case actionTypes.FETCH_CATEGORIES_START:
			return updateObject(state, { loading: true });
		case actionTypes.FETCH_CATEGORIES_SUCCESS:
			return updateObject(state, {
				loading: false,
				categories: action.categories,
				total: action.total,
			});
		case actionTypes.FETCH_CATEGORIES_FAIL:
			return updateObject(state, { loading: false });

		case actionTypes.CREATE_CATEGORIES_START:
			return updateObject(state, { loading: true });
		case actionTypes.CREATE_CATEGORIES_SUCCESS:
			return updateObject(state, {
				category: action.category,
				categories: [action.category, ...state.categories],
				loading: false,
			});
		case actionTypes.CREATE_CATEGORIES_FAIL:
			return updateObject(state, { loading: false });

		case actionTypes.UPDATE_CATEGORY_START:
			return updateObject(state, { loading: true });
		case actionTypes.UPDATE_CATEGORY_SUCCESS:
			return updateData(state, action.category);
		case actionTypes.UPDATE_CATEGORY_FAIL:
			return updateObject(state, { loading: false });

		case actionTypes.SORT_CATEGOR_START:
			return updateObject(state, { loading: true });
		case actionTypes.SORT_CATEGOR_SUCCESS:
			return updateObject(state, { loading: false });
		case actionTypes.SORT_CATEGOR_FAIL:
			return updateObject(state, { loading: false });

		case actionTypes.FETCH_CATEGOR_START:
			return updateObject(state, { loading: true });
		case actionTypes.FETCH_CATEGOR_SUCCESS:
			return updateObject(state, { category: action.category, loading: false });
		case actionTypes.FETCH_CATEGOR_FAIL:
			return updateObject(state, { loading: false });

		case actionTypes.UPDATE_PRODUCT_CATEGORY:
			return updateProduct(state, action.product);

		// case actionTypes.SORT_PRODUCTS:
		// 	return sortProducts(state, action.index);
		default:
			return state;
	}
};

export default categoriesReducer;
