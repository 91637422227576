import React, { Suspense } from "react";
import { Route, Redirect, Router } from "react-router-dom";
import { connect } from "react-redux";
import { isLoggedIn } from "./helpers/helpers";
import App from "./pages/App/App";
import { Spin } from "antd";

const Login = React.lazy(() => import("./components/Login/Login"));

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			localStorage.getItem("admin_token") ? (
				<Component {...props} />
			) : (
				<Redirect
					to={{
						pathname: "/login",
						state: { from: props.location },
					}}
				/>
			)
		}
	/>
);
const PublicRoutes = ({ history }) => {
	const style = {
		textAlign: "center",
		background: "#f1f3f6",
		padding: "30% 50%",
		height: "100vh",
	};
	const logedIn = isLoggedIn();

	return (
		<Router history={history}>
			<div>
				<Route
					exact
					path={"/"}
					render={(props) => (
						<Suspense
							fallback={
								<div style={style}>
									<Spin size="large" />
								</div>
							}>
							<Login {...props} />
						</Suspense>
					)}
				/>
				<Route
					exact
					path={"/login"}
					render={(props) => (
						<Suspense
							fallback={
								<div style={style}>
									<Spin size="large" />
								</div>
							}>
							<Login {...props} />
						</Suspense>
					)}
				/>

				{/* <Route
					exact
					path={"/404"}
					component={asyncComponent(() => import("./containers/Page/404"))}
				/>
				<Route
					exact
					path={"/403"}
					component={asyncComponent(() => import("./containers/Page/403"))}
				/> */}
				{/* <Route
					exact
					path={"/500"}
					component={asyncComponent(() => import("./containers/Page/500"))}
				/> */}

				<RestrictedRoute
					path="/dashboard"
					component={App}
					isLoggedIn={logedIn}
				/>
			</div>
		</Router>
	);
};

export default connect((state) => ({
	isLoggedIn: true, //state.Auth.get('idToken') !== null,
}))(PublicRoutes);
