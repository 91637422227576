//*******************************************/
//----------------PRODUCTS------------------/
//*******************************************/

/************** FETCH PRODUCTS *************/
export const FETCH_PRODUCTS_START = "FETCH_PRODUCTS_START";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAIL = "FETCH_PRODUCTS_FAIL";
export const REINIT_PRODUCTS = "REINIT_PRODUCTS";

/************** FETCH PRODUCT *************/
export const FETCH_PRODUCT_START = "FETCH_PRODUCT_START";
export const FETCH_PRODUCT_SUCCESS = "FETCH_PRODUCT_SUCCESS";
export const FETCH_PRODUCT_FAIL = "FETCH_PRODUCT_FAIL";

/************** CREATE PRODUCT *************/
export const CREATE_PRODUCTS_START = "CREATE_PRODUCTS_START";
export const CREATE_PRODUCTS_SUCCESS = "CREATE_PRODUCTS_SUCCESS";
export const CREATE_PRODUCTS_FAIL = "CREATE_PRODUCTS_FAIL";

export const FETCH_UPDATED_PRODUCTS_PRODUCTS_PRICES_START =
	"FETCH_UPDATED_PRODUCTS_PRODUCTS_PRICES_START";
export const FETCH_UPDATED_PRODUCTS_PRODUCTS_PRICES_SUCCESS =
	"FETCH_UPDATED_PRODUCTS_PRODUCTS_PRICES_SUCCESS";
export const FETCH_UPDATED_PRODUCTS_PRODUCTS_PRICES_FAIL =
	"FETCH_UPDATED_PRODUCTS_PRODUCTS_PRICES_FAIL";

/************** UPDATE PRODUCT *************/

export const UPDATE_PRODUCT_START = "UPDATE_PRODUCT_START";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAIL = "UPDATE_PRODUCT_FAIL";

export const SORT_PRODUCT_START = "SORT_PRODUCT_START";
export const SORT_PRODUCT_SUCCESS = "SORT_PRODUCT_SUCCESS";
export const SORT_PRODUCT_FAIL = "SORT_PRODUCT_FAIL";

export const SORT_PRODUCTS_START = "SORT_PRODUCTS_START";
export const SORT_PRODUCTS_SUCCESS = "SORT_PRODUCTS_SUCCESS";
export const SORT_PRODUCTS_FAIL = "SORT_PRODUCTS_FAIL";

export const SORT_PRODUCTS = "SORT_PRODUCTS";

export const UPDATE_SORT_PRODUCTS = "UPDATE_SORT_PRODUCTS";

/************** DELET PRODUCT MEDIA *************/

export const DELETE_PRODUCT_MEDIA_START = "DELETE_PRODUCT_MEDIA_START";
export const DELETE_PRODUCT_MEDIA_SUCCESS = "DELETE_PRODUCT_MEDIA_SUCCESS";
export const DELETE_PRODUCT_MEDIA_FAIL = "DELETE_PRODUCT_MEDIA_FAIL";

/************** GET PRODUCT OPTIONS *************/

export const FETCH_PRODUCT_OPTIONS_START = "FETCH_PRODUCT_OPTIONS_START";
export const FETCH_PRODUCT_OPTIONS_SUCCESS = "FETCH_PRODUCT_OPTIONS_SUCCESS";
export const FETCH_PRODUCT_OPTIONS_FAIL = "FETCH_PRODUCT_OPTIONS_FAIL";

export const GET_SORTED_PRODUCTS_START = "GET_SORTED_PRODUCTS_START";
export const GET_SORTED_PRODUCTS_SUCCESS = "GET_SORTED_PRODUCTS_SUCCESS";
export const GET_SORTED_PRODUCTS_FAIL = "GET_SORTED_PRODUCTS_FAIL";

//*******************************************/
//----------------USERS------------------/
//*******************************************/

export const REINIT_USERS = "REINIT_USERS";

/************** fetch users *************/
export const FETCH_USERS_START = "FETCH_USERS_START";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAIL = "FETCH_USERS_FAIL";

/************** create users *************/

export const CREATE_USER_START = "CREATE_USER_START";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAIL = "CREATE_USER_FAIL";
/************** update users *************/

export const UPDATE_USER_START = "UPDATE_USER_START";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

//*******************************************/
//--------------- Notifictions ------------------/
//*******************************************/

export const SUCCESS_MSG = "SUCCESS_MSG";
export const ERORR_MSG = "ERROR_MSG";
export const REINIT_MSG = "REINIT_MSG";

//*******************************************/
//----------------CATEGORIES------------------/
//*******************************************/

export const REINIT_CATEGORIES = "REINIT_CATEGORIES";

export const FETCH_CATEGORIES_START = "FETCH_CATEGORIES_START";
export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS";
export const FETCH_CATEGORIES_FAIL = "FETCH_CATEGORIES_FAIL";

export const CREATE_CATEGORIES_START = "CREATE_CATEGORIES_START";
export const CREATE_CATEGORIES_SUCCESS = "CREATE_CATEGORIES_SUCCESS";
export const CREATE_CATEGORIES_FAIL = "CREATE_CATEGORIES_FAIL";

export const UPDATE_CATEGORY_START = "UPDATE_CATEGORY_START";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAIL = "UPDATE_CATEGORY_FAIL";

export const SORT_CATEGOR_START = "SORT_CATEGOR_START";
export const SORT_CATEGOR_SUCCESS = "SORT_CATEGOR_SUCCESS";
export const SORT_CATEGOR_FAIL = "SORT_CATEGOR_FAIL";

export const FETCH_CATEGOR_START = "FETCH_CATEGOR_START";
export const FETCH_CATEGOR_SUCCESS = "FETCH_CATEGOR_SUCCESS";
export const FETCH_CATEGOR_FAIL = "FETCH_CATEGOR_FAIL";

//*******************************************/
//----------------ROLES------------------/
//*******************************************/

export const REINIT_ROLES = "REINIT_ROLES";

export const FETCH_ROLES_START = "FETCH_ROLES_START";
export const FETCH_ROLES_SUCCESS = "FETCH_ROLES_SUCCESS";
export const FETCH_ROLES_FAIL = "FETCH_ROLES_FAIL";

export const CREATE_ROLE_START = "CREATE_ROLE_START";
export const CREATE_ROLE_SUCCESS = "CREATE_ROLE_SUCCESS";
export const CREATE_ROLE_FAIL = "CREATE_ROLE_FAIL";

export const UPDATE_ROLE_START = "UPDATE_ROLE_START";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_FAIL = "UPDATE_ROLE_FAIL";

//*******************************************/
//----------------Orders------------------/
//*******************************************/

export const REINIT_ORDERS = "REINIT_ORDERS";

export const FETCH_ORDERS_START = "FETCH_ORDERS_START";
export const FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS";
export const FETCH_ORDERS_FAIL = "FETCH_ORDERS_FAIL";

export const UPDATE_ORDER_START = "UPDATE_ORDER_START";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_FAIL = "UPDATE_ORDER_FAIL";

export const FETCH_ORDER_START = "FETCH_ORDER_START";
export const FETCH_ORDER_SUCCESS = "FETCH_ORDER_SUCCESS";
export const FETCH_ORDER_FAIL = "FETCH_ORDER_FAIL";

export const CREATE_ORDER_SHIPMENT_START = "CREATE_ORDER_SHIPMENT_START";
export const CREATE_ORDER_SHIPMENT_SUCCESS = "CREATE_ORDER_SHIPMENT_SUCCESS";
export const CREATE_ORDER_SHIPMENT_FAIL = "CREATE_ORDER_SHIPMENT_FAIL";

export const PRINT_ORDER_START = "PRINT_ORDER_START";
export const PRINT_ORDER_SUCCESS = "PRINT_ORDER_SUCCESS";
export const PRINT_ORDER_FAIL = "PRINT_ORDER_FAIL";
//*******************************************/
//----------------Areas------------------/
//*******************************************/

export const FETCH_AREAS_START = "FETCH_AREAS_START";
export const FETCH_AREAS_SUCCESS = "FETCH_AREAS_SUCCESS";
export const FETCH_AREAS_FAIL = "FETCH_AREAS_FAIL";

export const CREATE_AREA_START = "CREATE_AREA_START";
export const CREATE_AREA_SUCCESS = "CREATE_AREA_SUCCESS";
export const CREATE_AREA_FAIL = "CREATE_AREA_FAIL";

export const UPDATE_AREA_START = "UPDATE_AREA_START";
export const UPDATE_AREA_SUCCESS = "UPDATE_AREA_SUCCESS";
export const UPDATE_AREA_FAIL = "UPDATE_AREA_FAIL";

export const REINIT_AREAS = "REINIT_AREAS";

//*******************************************/
//----------------Address------------------/
//*******************************************/

export const UPDATE_ADDRESS_START = "UPDATE_ADDRESS_START";
export const UPDATE_ADDRESS_SUCCESS = "UPDATE_ADDRESS_SUCCESS";
export const UPDATE_ADDRESS_FAIL = "UPDATE_ADDRESS_FAIL";

export const CREATE_ADDRESS_START = "CREATE_ADDRESS_START";
export const CREATE_ADDRESS_SUCCESS = "CREATE_ADDRESS_SUCCESS";
export const CREATE_ADDRESS_FAIL = "CREATE_ADDRESS_FAIL";

//*******************************************/
//----------------USER CART------------------/
//*******************************************/
export const REINIT_CART = "REINIT_CART";
export const GET_USER_CART_START = "GET_USER_CART_START;";
export const GET_USER_CART_SUCCESS = "GET_USER_CART_SUCCESS;";
export const GET_USER_CART_FAIL = "GET_USER_CART_FAIL;";

export const ADD_TO_USER_CART_START = "ADD_TO_USER_CART_START";
export const ADD_TO_USER_CART_SUCCESS = "ADD_TO_USER_CART_SUCCESS";
export const ADD_TO_USER_CART_FAIL = "ADD_TO_USER_CART_FAIL";

export const DELETE_CART_ITEM_START = "DELETE_CART_ITEM_START";
export const DELETE_CART_ITEM_SUCCESS = "DELETE_CART_ITEM_SUCCESS";
export const DELETE_CART_ITEM_FAIL = "DELETE_CART_ITEM_FAIL";

export const ADD_CART_ADDRESS_START = "ADD_CART_ADDRESS_START";
export const ADD_CART_ADDRESS_SUCCESS = "ADD_CART_ADDRESS_SUCCESS";
export const ADD_CART_ADDRESS_FAIL = "ADD_CART_ADDRESS_FAIL";

export const CREATE_USER_ORDER_START = "CREATE_USER_ORDER_START";
export const CREATE_USER_ORDER_SUCCESS = "CREATE_USER_ORDER_SUCCESS";
export const CREATE_USER_ORDER_FAIL = "CREATE_USER_ORDER_FAIL";

//*******************************************/
//---------------- Configs------------------/
//*******************************************/

export const FETCH_CONFIGS_START = "FETCH_CONFIGS_START";
export const FETCH_CONFIGS_SUCCESS = "FETCH_CONFIGS_SUCCESS";
export const FETCH_CONFIGS_FAIL = "FETCH_CONFIGS_FAIL";

export const UPDATE_CONFIGS_START = "UPDATE_CONFIGS_START";
export const UPDATE_CONFIGS_SUCCESS = "UPDATE_CONFIGS_SUCCESS";
export const UPDATE_CONFIGS_FAIL = "UPDATE_CONFIGS_FAIL";

export const UPDATE_PRODUCT_CATEGORY = "UPDATE_PRODUCT_CATEGORY";

//*******************************************/
//---------------- Coupons ------------------/
//*******************************************/

export const REINIT_COUPONS = "REINIT_COUPONS";

export const FETCH_COUPONS_START = "FETCH_COUPONS_START";
export const FETCH_COUPONS_SUCCESS = "FETCH_COUPONS_SUCCESS";
export const FETCH_COUPONS_FAIL = "FETCH_COUPONS_FAIL";

export const CREATE_COUPON_START = "CREATE_COUPON_START";
export const CREATE_COUPON_SUCCESS = "CREATE_COUPON_SUCCESS";
export const CREATE_COUPON_FAIL = "CREATE_COUPON_FAIL";

export const UPDATE_COUPON_START = "UPDATE_COUPON_START";
export const UPDATE_COUPON_SUCCESS = "UPDATE_COUPON_SUCCESS";
export const UPDATE_COUPON_FAIL = "UPDATE_COUPON_FAIL";

//*******************************************/
//---------------- Key words ------------------/
//*******************************************/

export const FETCH_KEY_WORDS_START = "FETCH_KEY_WORDS_START";
export const FETCH_KEY_WORDS_SUCCESS = "FETCH_KEY_WORDS_SUCCESS";
export const FETCH_KEY_WORDS_FAIL = "FETCH_KEY_WORDS_FAIL";

export const UPLOAD_PRODUCTS_START = "UPLOAD_PRODUCTS_START";
export const UPLOAD_PRODUCTS_SUCCESS = "UPLOAD_PRODUCTS_SUCCESS";
export const UPLOAD_PRODUCTS_FAIL = "UPLOAD_PRODUCTS_FAIL";

export const CHANGE_PRODUCTS_PRICES_START = "CHANGE_PRODUCTS_PRICES_START";
export const CHANGE_PRODUCTS_PRICES_SUCCESS = "CHANGE_PRODUCTS_PRICES_SUCCESS";
export const CHANGE_PRODUCTS_PRICES_FAIL = "CHANGE_PRODUCTS_PRICES_FAIL";
