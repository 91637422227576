const axios = require("axios");

export const getAddressInfo = (lat, lng, callback) => {
	return (dispatch) => {
		// dispatch(getAddressInfoStart());
		console.log("SSSS");
		axios
			.get(
				`https://maps.googleapis.com/maps/api/geocode/json?language=ar&key=AIzaSyCm7TROx-3ywRQqKJf4ziZgRB_JQacJmN0&latlng=${lat},${lng}`,
			)
			.then(function (response) {
				console.log("EEEEEE");
				callback(response.data);
			})
			.catch(function (error) {
				// handle error
			})
			.then(function () {
				// always executed
			});
	};
};
