import axios from "../axios/axiosMultiForm";
import jsonAxios from "../axios/axios-orders";
import * as actionTypes from "./actionTypes";

export const reInitCategories = () => ({
	type: actionTypes.REINIT_CATEGORIES,
});

const fetchCategoriesStart = () => ({
	type: actionTypes.FETCH_CATEGORIES_START,
});

const fetchCategoriesSuccess = (data, total) => ({
	type: actionTypes.FETCH_CATEGORIES_SUCCESS,
	categories: data,
	total: total,
});

const fetchCategoriesFail = () => ({
	type: actionTypes.FETCH_CATEGORIES_FAIL,
});

export const fetchCategories = (data = {}) => {
	return (dispatch) => {
		dispatch(fetchCategoriesStart());
		data["sort"] = "sort";
		var url = `admin/categories?`;
		for (var key in data) {
			url += key + "=" + data[key] + "&";
		}
		axios
			.get(url)
			.then((res) => {
				if (res.status === 200 || res.status === 201) {
					dispatch(fetchCategoriesSuccess(res.data.data, res.data.total));
					dispatch(fetchCategoriesFail());
				}
			})
			.catch((err) => {
				dispatch(fetchCategoriesFail());
			});
	};
};

const createCategoryStart = () => ({
	type: actionTypes.CREATE_CATEGORIES_START,
});

const createCategorySuccess = (data) => ({
	type: actionTypes.CREATE_CATEGORIES_SUCCESS,
	category: data,
});

const createCategoryFail = () => ({
	type: actionTypes.CREATE_CATEGORIES_FAIL,
});

export const createCategory = (data, callback) => {
	return (dispatch) => {
		dispatch(createCategoryStart());

		axios
			.post("admin/categories", data)
			.then((res) => {
				if (res.status === 200) {
					console.log({ eee: res.data.data });
					dispatch(createCategorySuccess(res.data.data, res.data.total));
					dispatch({
						type: actionTypes.SUCCESS_MSG,
						message: res.data.message,
					});
					callback && callback();
				} else {
					dispatch(createCategoryFail());
					dispatch({ type: actionTypes.ERORR_MSG, message: res.data.message });
				}
			})
			.catch((err) => {
				dispatch(createCategoryFail());
				dispatch({
					type: actionTypes.ERORR_MSG,
					message: ((err.response || {}).data || {}).message || "create fail",
				});
			});
	};
};

const updateCategoryStart = () => ({
	type: actionTypes.UPDATE_CATEGORY_START,
});

const updateCategorySuccess = (data, total) => ({
	type: actionTypes.UPDATE_CATEGORY_SUCCESS,
	category: data,
});

const updateCategoryFail = () => ({
	type: actionTypes.UPDATE_CATEGORY_FAIL,
});

export const updateCategory = (categoryId, data, callback) => {
	return (dispatch) => {
		dispatch(updateCategoryStart());

		axios
			.patch(`admin/categories/${categoryId}`, data)
			.then((res) => {
				if (res.status === 200) {
					dispatch(updateCategorySuccess(res.data.data));
					dispatch({
						type: actionTypes.SUCCESS_MSG,
						message: res.data.message,
					});

					callback && callback();
				} else {
					dispatch(updateCategoryFail());
					dispatch({ type: actionTypes.ERORR_MSG, message: res.data.message });
				}
			})
			.catch((err) => {
				dispatch(updateCategoryFail());
				dispatch({
					type: actionTypes.ERORR_MSG,
					message: err.response ? err.response.data.message : err,
				});
			});
	};
};

const sortCategoriesStart = () => ({
	type: actionTypes.SORT_CATEGOR_START,
});

const sortCategoriesSuccess = () => ({
	type: actionTypes.SORT_CATEGOR_SUCCESS,
});

const sortCategoriesFail = () => ({
	type: actionTypes.SORT_CATEGOR_FAIL,
});

export const sortCategories = (data) => {
	return (dispatch) => {
		dispatch(sortCategoriesStart());

		jsonAxios
			.put(`admin/categories/sort`, data)
			.then((res) => {
				if (res.status === 200) {
					dispatch(sortCategoriesSuccess());
					dispatch({
						type: actionTypes.SUCCESS_MSG,
						message: res.data.message,
					});
				} else {
					dispatch(sortCategoriesFail());
					dispatch({ type: actionTypes.ERORR_MSG, message: res.data.message });
				}
			})
			.catch((err) => {
				dispatch(sortCategoriesFail());
				console.log("err.response", err);
				dispatch({
					type: actionTypes.ERORR_MSG,
					message: err.response ? err.response?.data?.message : err.message,
				});
			});
	};
};

const getCatgeoryStart = () => ({
	type: actionTypes.FETCH_CATEGOR_START,
});

const getCatgeorySuccess = (category) => ({
	type: actionTypes.FETCH_CATEGOR_SUCCESS,
	category,
});

const getCatgeoryFail = () => ({
	type: actionTypes.FETCH_CATEGOR_FAIL,
});

export const getCatgeory = (Id, search) => {
	return (dispatch) => {
		dispatch(getCatgeoryStart());

		const queryParams = search ? `search=${search}` : "";
		axios
			.get(`admin/categories/${Id}?${queryParams}`)
			.then((res) => {
				if (res.status === 200) {
					dispatch(getCatgeorySuccess(res.data.data));
				} else {
					dispatch(getCatgeoryFail());
				}
			})
			.catch((err) => {
				dispatch(getCatgeoryFail());
			});
	};
};
