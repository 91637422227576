export const updateObject = (oldObject, updatedValues) => ({
	...oldObject,
	...updatedValues,
});

export const getView = (width) => {
	let newView = "MobileView";
	if (width > 1220) {
		newView = "DesktopView";
	} else if (width > 767) {
		newView = "TabView";
	}
	return newView;
};

export const convertObjToParams = (list = {}) => {
	let url = "";

	for (var key in list) {
		url += key + "=" + list[key] + "&";
	}

	return url;
};

export const filterEvents = (dates) => {
	const month = getList(prepairMonth(dates));
	const week = getList(prepairWeek(dates));
	const day = getDayList(prepairWeek(dates));

	return { month, week, day };
};

const getDayList = (dates = []) => {
	const list = [];

	for (let time in dates) {
		const orders = dates[time];

		const assigned = [];
		const unassigned = [];

		orders.forEach((order, index) => {
			const isAssigned = Boolean(order.outfitter);
			const visitTime = new Date(time);
			const start = isAssigned
				? visitTime
				: new Date(new Date(time).setMinutes(30, 0));
			const end = isAssigned
				? visitTime
				: new Date(new Date(time).setMinutes(30, 0));

			(isAssigned ? assigned : unassigned).push(order);

			list.push({
				id: time + "assigned",
				index: isAssigned ? assigned.length - 1 : unassigned.length - 1,
				allDay: false,
				title: `${order._id} | ${order.user.username}`,
				desc: "",
				visitTime,
				start: start,
				end: end,
				orders: [order],
				type: isAssigned ? "assigned" : "not-assigned",
			});
		});
	}
	return list;
};

const prepairWeek = (dates = []) => {
	const prepairedAsDays = {};

	for (let time in dates) {
		const orders = dates[time];

		const key = new Date(time);
		const isExistDay = Boolean(prepairedAsDays[key]);

		orders.map((order) => (order.visitTime = new Date(time)));

		isExistDay
			? (prepairedAsDays[key] = prepairedAsDays[key].concat([...orders]))
			: (prepairedAsDays[key] = [...orders]);
	}

	return prepairedAsDays;
};

const prepairMonth = (dates = []) => {
	const prepairedAsDays = {};

	for (let time in dates) {
		const orders = dates[time];

		orders.map((order) => (order.visitTime = new Date(time)));

		const key = new Date(time).toDateString();
		const isExistDay = Boolean(prepairedAsDays[key]);

		isExistDay
			? (prepairedAsDays[key] = prepairedAsDays[key].concat([...orders]))
			: (prepairedAsDays[key] = [...orders]);
	}

	return prepairedAsDays;
};

const getList = (dates = []) => {
	const list = [];

	for (let time in dates) {
		const orders = dates[time];
		let assigned = [];
		let notAssigned = [];

		orders.forEach((order) => {
			(Boolean(order.outfitter) ? assigned : notAssigned).push(order);
		});

		assigned.length > 0 &&
			list.push({
				id: time + "assigned",
				allDay: false,
				title: `${assigned.length} Orders`,
				desc: "",
				visitTime: new Date(time),
				start: new Date(time),
				end: new Date(time),
				orders: assigned,
				type: "assigned",
			});

		notAssigned.length > 0 &&
			list.push({
				id: time + "not-assigned",
				allDay: false,
				title: `${notAssigned.length} Orders`,
				desc: "",
				visitTime: new Date(time),
				start: new Date(new Date(time).setMinutes(30, 0)),
				end: new Date(new Date(time).setMinutes(30, 0)),
				orders: notAssigned,
				type: "not-assigned",
			});
	}
	return list;
};
