import React, { useState } from "react";
import { Layout, Avatar, Popover } from "antd";
import {
	MenuUnfoldOutlined,
	MenuFoldOutlined,
	UserOutlined,
} from "@ant-design/icons";
import { withRouter } from "react-router";

const { Header } = Layout;

const HeaderLayout = (props) => {
	const [visible, setVisible] = useState(false);

	const handleVisibleChange = () => {
		setVisible(!visible);
	};

	const logoutHandler = async () => {
		await localStorage.removeItem("admin_token");

		props.history.push("/");
	};
	return (
		<Header className="site-layout-background" style={{ padding: 0 }}>
			{/* {logout && <Redirect to="/" />} */}
			{React.createElement(
				props.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
				{
					className: "trigger",
					onClick: props.toggle,
				},
			)}
			<div style={{ position: "relative", bottom: "100%", left: "90%" }}>
				<Popover
					content={<a onClick={logoutHandler}>logout</a>}
					trigger="click"
					visible={visible}
					onVisibleChange={handleVisibleChange}>
					<Avatar icon={<UserOutlined />} />
				</Popover>
			</div>
		</Header>
	);
};

export default withRouter(HeaderLayout);
