import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
	users: [],
	user: {},
	total: 0,
	token: "",
	loading: false,
};

const updateData = (state, updateduser) => {
	var users = [];

	state.users.forEach((user) => {
		if (updateduser._id !== user._id) {
			users.push(user);
		} else if (!updateduser.is_deleted) {
			users.push(updateduser);
		}
	});

	return updateObject(state, {
		users: users,
		user: updateduser,
		loading: false,
		total: updateduser.is_deleted ? state.total - 1 : state.total,
	});
};

const usersReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.REINIT_ROLES:
			return updateObject(state, initialState);
		case actionTypes.FETCH_USERS_START:
			return updateObject(state, { loading: true });
		case actionTypes.FETCH_USERS_SUCCESS:
			return updateObject(state, {
				loading: false,
				users: action.users,
				total: action.total,
			});
		case actionTypes.FETCH_USERS_FAIL:
			return updateObject(state, { loading: false });

		case actionTypes.CREATE_USER_START:
			return updateObject(state, { loading: true });
		case actionTypes.CREATE_USER_SUCCESS:
			return updateObject(state, {
				loading: false,
				users: [action.user, ...state.users],
			});
		case actionTypes.CREATE_USER_FAIL:
			return updateObject(state, { loading: false });

		case actionTypes.UPDATE_USER_START:
			return updateObject(state, { loading: true });
		case actionTypes.UPDATE_USER_SUCCESS:
			return updateData(state, action.user);
		case actionTypes.UPDATE_USER_FAIL:
			return updateObject(state, { loading: false });

		case actionTypes.LOGIN_START:
			return updateObject(state, { loading: true });
		case actionTypes.LOGIN_SUCCESS:
			return updateObject(state, { loading: false, token: action.token });
		case actionTypes.LOGIN_FAIL:
			return updateObject(state, { loading: false });

		default:
			return state;
	}
};

export default usersReducer;
