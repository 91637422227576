import axios from "../axios/axios-orders";
import * as actionTypes from "./actionTypes";

export const reInitRole = () => ({
	type: actionTypes.REINIT_ROLES,
});

const fetchRolesStart = () => ({
	type: actionTypes.FETCH_ROLES_START,
});

const fetchRolesSuccess = (data, total) => ({
	type: actionTypes.FETCH_ROLES_SUCCESS,
	roles: data,
	total: total,
});

const fetchRolesFail = () => ({
	type: actionTypes.FETCH_ROLES_FAIL,
});

export const fetchRoles = (data = {}) => {
	return (dispatch) => {
		dispatch(fetchRolesStart());
		data["sort"] = "-createdAt";
		var url = `admin/roles?`;
		for (var key in data) {
			url += key + "=" + data[key] + "&";
		}
		axios
			.get(url)
			.then((res) => {
				if (res.status === 200 || res.status === 201) {
					dispatch(fetchRolesSuccess(res.data.data, res.data.total));
					dispatch(fetchRolesFail());
				}
			})
			.catch((err) => {
				dispatch(fetchRolesFail());
			});
	};
};

const createRolesStart = () => ({
	type: actionTypes.CREATE_ROLE_START,
});

const createRolesSuccess = (data) => ({
	type: actionTypes.CREATE_ROLE_SUCCESS,
	role: data,
});

const createRolesFail = () => ({
	type: actionTypes.CREATE_ROLE_FAIL,
});

export const createRoles = (data, callback) => {
	return (dispatch) => {
		dispatch(createRolesStart());

		axios
			.post("admin/roles", data)
			.then((res) => {
				if (res.status === 200) {
					dispatch(createRolesSuccess(res.data.data, res.data.total));
					dispatch({
						type: actionTypes.SUCCESS_MSG,
						message: res.data.message,
					});
					callback && callback();
				} else {
					dispatch(createRolesFail());
					dispatch({ type: actionTypes.ERORR_MSG, message: res.data.message });
				}
			})
			.catch((err) => {
				dispatch(createRolesFail());
				dispatch({
					type: actionTypes.ERORR_MSG,
					message: ((err.response || {}).data || {}).message || "create fail",
				});
			});
	};
};

const updateRoleStart = () => ({
	type: actionTypes.UPDATE_ROLE_START,
});

const updateRoleSuccess = (data, total) => ({
	type: actionTypes.UPDATE_ROLE_SUCCESS,
	role: data,
});

const updateRoleFail = () => ({
	type: actionTypes.UPDATE_ROLE_FAIL,
});

export const updateRole = (roleId, data, callback) => {
	return (dispatch) => {
		dispatch(updateRoleStart());

		axios
			.patch(`admin/roles/${roleId}`, data)
			.then((res) => {
				if (res.status === 200) {
					dispatch(updateRoleSuccess(res.data.data));
					dispatch({
						type: actionTypes.SUCCESS_MSG,
						message: res.data.message,
					});

					callback && callback();
				} else {
					dispatch(updateRoleFail());
					dispatch({ type: actionTypes.ERORR_MSG, message: res.data.message });
				}
			})
			.catch((err) => {
				dispatch(updateRoleFail());
				dispatch({
					type: actionTypes.ERORR_MSG,
					message: err.response ? err.response.data.message : err,
				});
			});
	};
};
