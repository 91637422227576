import axios from "../axios/axios-orders";
import * as actionTypes from "./actionTypes";

const fetchKeyWordsStart = () => ({
	type: actionTypes.FETCH_KEY_WORDS_START,
});

const fetchKeyWordsSuccess = (data, total) => ({
	type: actionTypes.FETCH_KEY_WORDS_SUCCESS,
	keyWords: data,
	total: total,
});

const fetchKeyWordsFail = () => ({
	type: actionTypes.FETCH_KEY_WORDS_FAIL,
});

export const fetchKeyWords = (data = {}) => {
	return (dispatch) => {
		dispatch(fetchKeyWordsStart());
		data["sort"] = "-createdAt";
		var url = `admin/key-words?`;
		for (var key in data) {
			url += key + "=" + data[key] + "&";
		}
		axios
			.get(url)
			.then((res) => {
				if (res.status === 200 || res.status === 201) {
					dispatch(fetchKeyWordsSuccess(res.data.data, res.data.total));
				} else dispatch(fetchKeyWordsFail());
			})
			.catch((err) => {
				dispatch(fetchKeyWordsFail());
			});
	};
};
