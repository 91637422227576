import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
	sortProducts: [],
	total: 0,
	loading: false,
};

const updateProduct = (state, updatedProduct) => {
	const products = state.sortProducts.sort((a, b) => a.sort - b.sort);
	let sortProducts = []; // products.map((element) => {
	for (let index = 0; index < products.length; index++) {
		const element = products[index];

		if (updatedProduct._id !== element.product._id) {
			sortProducts.push(element);
		} else {
			let currentSortProduct = { ...element };
			currentSortProduct.product = updatedProduct;
			sortProducts.push(currentSortProduct);
		}
	}
	// });

	return updateObject(state, {
		sortProducts: sortProducts,
		loading: false,
		total: state.total,
	});
};

const sortProducts = (state, index) => {
	// let category = state.sortProducts;
	let products = state.sortProducts || [];

	const currentProduct = products.splice(index, 1)[0];
	currentProduct.sort = 1;
	products.unshift(currentProduct);

	products = products.map((el, index) => {
		el.sort = index + 1;
		return el;
	});

	return updateObject(state, {
		sortProducts: products,
		loading: false,
	});
};

const sortProductsReducer = (state = initialState, action) => {
	switch (action.type) {
		// case actionTypes.REINIT_AREAS:
		// 	return updateObject(state, initialState);
		case actionTypes.GET_SORTED_PRODUCTS_START:
			return updateObject(state, { loading: true });
		case actionTypes.GET_SORTED_PRODUCTS_SUCCESS:
			return updateObject(state, {
				loading: false,
				sortProducts: action.sortProducts,
				total: action.total,
			});
		case actionTypes.GET_SORTED_PRODUCTS_FAIL:
			return updateObject(state, { loading: false });

		case actionTypes.SORT_PRODUCTS:
			return sortProducts(state, action.index);
		case actionTypes.UPDATE_SORT_PRODUCTS:
			return updateProduct(state, action.product);
		case actionTypes.SORT_PRODUCTS_START:
			return updateObject(state, { loading: true });
		case actionTypes.SORT_PRODUCTS_SUCCESS:
			return updateObject(state, {
				sortProducts: action.sortedProducts,
				loading: false,
			});
		case actionTypes.SORT_PRODUCTS_FAIL:
			return updateObject(state, { loading: false });

		default:
			return state;
	}
};

export default sortProductsReducer;
