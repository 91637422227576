import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
	orders: [],
	order: {},
	loading: false,
};

const ordersReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.REINIT_ORDERS:
			return updateObject(state, initialState);
		case actionTypes.FETCH_ORDERS_START:
			return updateObject(state, { loading: true });
		case actionTypes.FETCH_ORDERS_SUCCESS:
			return updateObject(state, {
				loading: false,
				orders: action.orders,
				total: action.total,
			});
		case actionTypes.FETCH_ORDERS_FAIL:
			return updateObject(state, { loading: false });

		case actionTypes.FETCH_ORDER_START:
			return updateObject(state, { loading: true });
		case actionTypes.FETCH_ORDER_SUCCESS:
			return updateObject(state, {
				loading: false,
				order: action.order,
			});
		case actionTypes.FETCH_ORDER_FAIL:
			return updateObject(state, { loading: false });

		case actionTypes.UPDATE_ORDER_START:
			return updateObject(state, { loading: true });
		case actionTypes.UPDATE_ORDER_SUCCESS:
			return updateObject(state, {
				loading: false,
				order: action.order,
			});
		case actionTypes.UPDATE_ROLE_FAIL:
			return updateObject(state, { loading: false });

		case actionTypes.CREATE_ORDER_SHIPMENT_START:
			return updateObject(state, { loading: true });
		case actionTypes.CREATE_ORDER_SHIPMENT_SUCCESS:
			return updateObject(state, {
				loading: false,
				order: action.order,
			});
		case actionTypes.CREATE_ORDER_SHIPMENT_FAIL:
			return updateObject(state, { loading: false });

		case actionTypes.CREATE_USER_ORDER_START:
			return updateObject(state, { loading: true });
		case actionTypes.CREATE_USER_ORDER_SUCCESS:
			return updateObject(state, {
				loading: false,
				order: action.order,
			});
		case actionTypes.CREATE_USER_ORDER_FAIL:
			return updateObject(state, { loading: false });

		case actionTypes.PRINT_ORDER_START:
			return updateObject(state, { loading: true });
		case actionTypes.PRINT_ORDER_SUCCESS:
			console.log("SSSSSS");
			return updateObject(state, { loading: false });
		case actionTypes.PRINT_ORDER_FAIL:
			return updateObject(state, { loading: false });
		default:
			return state;
	}
};

export default ordersReducer;
