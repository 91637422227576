import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
	cart: {},
	loading: false,
};

// const updateData = (state, updatedArea) => {
// 	var areas = [];

// 	state.areas.forEach((product) => {
// 		if (updatedArea._id !== product._id) {
// 			areas.push(product);
// 		} else if (!updatedArea.is_deleted) {
// 			areas.push(updatedArea);
// 		}
// 	});

// 	return updateObject(state, {
// 		areas: areas,
// 		product: updatedArea,
// 		loading: false,
// 		total: state.total - 1,
// 	});
// };

const areasReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.REINIT_CART:
			return updateObject(state, initialState);
		case actionTypes.GET_USER_CART_START:
			return updateObject(state, { loading: true });
		case actionTypes.GET_USER_CART_SUCCESS:
			return updateObject(state, {
				loading: false,
				cart: action.cart,
			});

		case actionTypes.ADD_TO_USER_CART_START:
			return updateObject(state, { loading: true });
		case actionTypes.ADD_TO_USER_CART_SUCCESS:
			return updateObject(state, {
				loading: false,
				cart: action.cart,
			});
		case actionTypes.ADD_TO_USER_CART_FAIL:
			return updateObject(state, { loading: false });

		case actionTypes.DELETE_CART_ITEM_START:
			return updateObject(state, { loading: true });
		case actionTypes.DELETE_CART_ITEM_SUCCESS:
			return updateObject(state, {
				loading: false,
				cart: action.cart,
			});
		case actionTypes.DELETE_CART_ITEM_FAIL:
			return updateObject(state, { loading: false });

		case actionTypes.ADD_CART_ADDRESS_START:
			return updateObject(state, { loading: true });
		case actionTypes.ADD_CART_ADDRESS_SUCCESS:
			return updateObject(state, {
				loading: false,
				cart: action.cart,
			});
		case actionTypes.ADD_CART_ADDRESS_FAIL:
			return updateObject(state, { loading: false });

		default:
			return state;
	}
};

export default areasReducer;
