export const isLoggedIn = () => {
	const token = localStorage.getItem("admin_token");
	return token ? true : false;
};

export const getToken = () => {
	const token = localStorage.getItem("admin_token");
	return token;
};

export const formatDate = (date) => {
	date = new Date(date);
	const day = date.getDate(),
		month = date.getMonth() + 1,
		year = date.getFullYear();

	return day + "/" + month + "/" + year;
};

export const toFixed = (value) =>
	parseFloat(parseFloat(value).toPrecision(12)).toFixed(2);

export const compare = (key, order = "asc") => {
	return function innerSort(a, b) {
		let varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
		let varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

		let comparison = 0;
		if (varA > varB) {
			comparison = 1;
		} else if (varA < varB) {
			comparison = -1;
		}
		return order === "desc" ? comparison * -1 : comparison;
	};
};

export const getPriceRange = (units) => {
	let best = [];
	for (var unit in units) {
		let cost = units[unit].item_details.price;
		cost = Number(cost);
		if (cost)
			best.push({ store: units[unit].vendor_details, cost, vendor_id: unit });
	}
	best = best.sort(compare("cost", "asc"));
	const min = best[0]["cost"];
	const max = best[best.length - 1]["cost"];
	return { min, max };
};

export const startingFrom = (units) => {
	let prices = [];
	for (var unit in units) {
		let price = units[unit].item_details.price;
		if (price) prices.push(price);
	}
	return prices.length ? Math.min(...prices) : 0;
};

export const categoriesList = [
	"Fruits & Vegetables",
	"Bakery",
	"Butchery",
	"Deli",
	"Dairy, Eggs & Butter",
	"Food Cupboard",
	"Tea, Coffee & Soft Drinks",
	"Frozen",
	"Personal Care",
	"Laundry & Household",
	"Baby & Kids",
	"Pets",
	"Discounts",
	"Offers",
];

export const statuses = [
	{ value: "received", label: "RECEIVED" },
	{ value: "processing", label: "PROCESSING" },
	{ value: "ready", label: "READY" },
	{ value: "delivering", label: "DELIVERING" },
	{ value: "delivered", label: "DELIVERED" },
	{ value: "cancelled", label: "CANCELLED" },
];

export const quantityOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const customStyles = {
	headRow: {
		style: {
			borderTopStyle: "solid",
			borderTopWidth: "1px",
			borderTopColor: "#e8e8e8",
			borderBottomStyle: "solid",
			borderBottomWidth: "1px",
			borderBottomColor: "transparent",
			backgroundColor: "rgb(241, 243, 246)",
		},
	},
	headCells: {
		style: {
			":last-of-type": {
				borderRightStyle: "solid",
				borderRightWidth: "1px",
				borderRightColor: "#e8e8e8",
			},
			borderLeftStyle: "solid",
			borderLeftWidth: "1px",
			borderLeftColor: "#e8e8e8",
			paddingTop: "15px",
			paddingBottom: "15px",
		},
	},
	cells: {
		style: {
			":last-of-type": {
				borderRightStyle: "solid",
				borderRightWidth: "1px",
				borderRightColor: "#e8e8e8",
			},
			borderLeftStyle: "solid",
			borderLeftWidth: "1px",
			borderLeftColor: "#e8e8e8",
			paddingTop: "15px",
			paddingBottom: "15px",
		},
	},
};

export const checkPermission = (
	permissions = {},
	resourse = "",
	permission,
) => {
	return true;
	// return (
	// 	Object.keys(permissions).includes(resourse) &&
	// 	permissions[resourse].includes(permission)
	// );
};
function validateEmail(email) {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	return re.test(String(email).toLowerCase());
}

export const checkFields = (dataValidation, data) => {
	for (let index = 0; index < dataValidation.length; index++) {
		const element = dataValidation[index];
		if (element.type === "string") {
			if (
				data[element.name]?.length < element.minLength ||
				data[element.name]?.length > element.maxLength
			) {
				return {
					error: true,
					message: `${element.label} should be more than ${
						element.minLength - 1
					} and less than ${element.maxLength + 1} char `,
				};
			}
		} else if (element.type === "phone") {
			if (
				!data[element.name] ||
				data[element.name].length !== 10 ||
				!data[element.name].startsWith("07")
			) {
				return {
					error: true,
					message: `${element.label} is incorrect`,
				};
			}
		} else if (element.type === "email") {
			if (!validateEmail(data[element.name])) {
				return {
					error: true,
					message: `${element.label} is incorrect`,
				};
			}
		} else if (element.type === "password") {
			if (
				data[element.name] &&
				(data[element.name].length < element.minLength ||
					data[element.name].length > element.maxLength)
			) {
				return {
					error: true,
					message: `${element.label} should be more than ${
						element.minLength - 1
					} char `,
				};
			}
		} else if (element.type === "select") {
			if (element.required && !data[element.name]) {
				return {
					error: true,
					message: `please select an option from ${element.label}`,
				};
			}
		}
	}
	return { error: false };
};

export const getTotalPrice = (items = []) => {
	let total = 0;

	for (let index = 0; index < items.length; index++) {
		const item = items[index];
		total += item.price * item.quantity;
	}
	return total.toFixed(2);
};

export const generate_id = (len = 17) => {
	return Math.random().toString(36).substr(2, 8);
};

export const days = [
	"السبت",
	"اﻷحد",
	"اﻷثنين",
	"الثلاثاء",
	"اﻷربعاء",
	"الخميس",
	"الجمعة",
];

export const hours = [
	{ value: 0, label: "12 am" },
	{ value: 1, label: "1 am" },
	{ value: 2, label: "2 am" },
	{ value: 3, label: "3 am" },
	{ value: 4, label: "4 am" },
	{ value: 5, label: "5 am" },
	{ value: 6, label: "6 am" },
	{ value: 7, label: "7 am" },
	{ value: 8, label: "8 am" },
	{ value: 9, label: "9 am" },
	{ value: 10, label: "10 am" },
	{ value: 11, label: "11 am" },
	{ value: 12, label: "12 pm" },
	{ value: 13, label: "1 pm" },
	{ value: 14, label: "2 pm" },
	{ value: 15, label: "3 pm" },
	{ value: 16, label: "4 pm" },
	{ value: 17, label: "5 pm" },
	{ value: 18, label: "6 pm" },
	{ value: 19, label: "7 pm" },
	{ value: 20, label: "8 pm" },
	{ value: 21, label: "9 pm" },
	{ value: 22, label: "10 pm" },
	{ value: 23, label: "11 pm" },
];

export const layout = {
	labelCol: {
		span: 2,
	},
	wrapperCol: {
		span: 16,
	},
};
