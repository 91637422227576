import axios from "../axios/axios-orders";
import loginAxios from "../axios/auth";
import * as actionTypes from "./actionTypes";

export const reInitUsers = () => ({
	type: actionTypes.REINIT_USERS,
});

const fetchUsersStart = () => ({
	type: actionTypes.FETCH_USERS_START,
});

const fetchUsersSuccess = (data, total) => ({
	type: actionTypes.FETCH_USERS_SUCCESS,
	users: data,
	total: total,
});

const fetchUsersFail = () => ({
	type: actionTypes.FETCH_USERS_FAIL,
});

export const fetchUsers = (data) => {
	return (dispatch) => {
		dispatch(fetchUsersStart());
		var url = `admin/users?`;
		for (var key in data) {
			url += key + "=" + data[key] + "&";
		}
		axios
			.get(url)
			.then((res) => {
				if (res.status === 200 || res.status === 201) {
					dispatch(fetchUsersSuccess(res.data.data, res.data.total));
				} else {
					dispatch(fetchUsersFail());
				}
			})
			.catch((err) => {
				dispatch(fetchUsersFail());
			});
	};
};

const createUserStart = () => ({
	type: actionTypes.CREATE_USER_START,
});

const createUserSuccess = (data) => ({
	type: actionTypes.CREATE_USER_SUCCESS,
	user: data,
});

const createUserFail = () => ({
	type: actionTypes.CREATE_USER_FAIL,
});

export const createUser = (formData, callBack) => {
	return (dispatch) => {
		dispatch(createUserStart());

		axios
			.post(`admin/users`, formData)
			.then((res) => {
				if (res.status === 200 || res.status === 201) {
					dispatch(createUserSuccess(res.data.data));

					dispatch({
						type: actionTypes.SUCCESS_MSG,
						message: res.data.message,
					});
					callBack && callBack(res.data.data);
				} else {
					dispatch(createUserFail());
					dispatch({
						type: actionTypes.ERORR_MSG,
						message:
							((res.response || {}).data || {}).message || "create user fail",
					});
				}
			})
			.catch((err) => {
				dispatch(createUserFail());
				dispatch({
					type: actionTypes.ERORR_MSG,
					message:
						((err.response || {}).data || {}).message || "create user fail",
				});
			});
	};
};

const updateUserStart = () => ({
	type: actionTypes.UPDATE_USER_START,
});

const updateUserSuccess = (data) => ({
	type: actionTypes.UPDATE_USER_SUCCESS,
	user: data,
});

const updateUserFail = () => ({
	type: actionTypes.UPDATE_USER_FAIL,
});

export const updateUser = (userId, formData, callback) => {
	return (dispatch) => {
		dispatch(updateUserStart());

		axios
			.patch(`admin/users/${userId}`, formData)
			.then((res) => {
				if (res.status === 200 || res.status === 201) {
					dispatch(updateUserSuccess(res.data.data, res.data.total));
					dispatch({
						type: actionTypes.SUCCESS_MSG,
						message: res.data.message,
					});
					callback && callback();
				} else {
					dispatch(updateUserFail());
				}
			})
			.catch((err) => {
				dispatch(updateUserFail());
				dispatch({
					type: actionTypes.ERORR_MSG,
					message: ((err.response || {}).data || {}).message || "update fail",
				});
			});
	};
};

const loginStart = () => ({
	type: actionTypes.LOGIN_START,
});

const loginSuccess = (data) => ({
	type: actionTypes.LOGIN_SUCCESS,
	token: data,
});

const loginFail = () => ({
	type: actionTypes.LOGIN_FAIL,
});

export const login = (body, callback) => {
	return (dispatch) => {
		dispatch(loginStart());

		loginAxios
			.post(`users/login`, { ...body, source: "admin" })
			.then((res) => {
				if (res.status === 200) {
					localStorage.setItem("admin_token", res.data.token);

					//&&
					dispatch(loginSuccess(res.data.token));
					dispatch({
						type: actionTypes.SUCCESS_MSG,
						message: res.data.message,
					});
					callback && callback();
				} else {
					dispatch(loginFail());
				}
			})
			.catch((err) => {
				dispatch(loginFail());
				dispatch({
					type: actionTypes.ERORR_MSG,
					message: ((err.response || {}).data || {}).message || "update fail",
				});
			});
	};
};
