import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LabibaTable from "../../components/LabibaTable";
import { tableinfos } from "./colums";
import { fetchUpdatedProducts } from "../../redux/actions";
import { Button, Spin, PageHeader, Input } from "antd";

const Search = Input.Search;

const UpdatedProducts = (props) => {
	const dispatch = useDispatch();
	const { totalUpdatedProducts, updatedProducts, loading } = useSelector(
		(state) => state.Products,
	);

	useEffect(() => {
		dispatch(fetchUpdatedProducts());

		return () => {};
	}, []);

	const onSearch = (searchText) => {
		let search = { search: searchText };
		if (!searchText) {
			search = {};
		}
		dispatch(fetchUpdatedProducts(search));
	};

	return (
		<Spin spinning={loading}>
			<PageHeader
				className="site-page-header"
				title="Updated products"
				extra={[
					<Search
						style={{ width: 200 }}
						placeholder="input search text"
						onSearch={onSearch}
						enterButton
					/>,
				]}></PageHeader>
			<LabibaTable
				onFetchData={fetchUpdatedProducts}
				data={updatedProducts}
				total={totalUpdatedProducts}
				columns={tableinfos}
			/>
		</Spin>
	);
};

export default UpdatedProducts;
