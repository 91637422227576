import axios from "../axios/axiosMultiForm";
import axiosJson from "../axios/axios-orders";
import * as actionTypes from "./actionTypes";

export const reInitProducts = () => ({
	type: actionTypes.REINIT_PRODUCTS,
});

const fetchProductsStart = () => ({
	type: actionTypes.FETCH_PRODUCTS_START,
});

const fetchProductsSuccess = (data, total) => ({
	type: actionTypes.FETCH_PRODUCTS_SUCCESS,
	products: data,
	total: total,
});

const fetchProductsFail = () => ({
	type: actionTypes.FETCH_PRODUCTS_FAIL,
});

export const fetchProducts = (data = {}) => {
	return (dispatch) => {
		dispatch(fetchProductsStart());
		data["sort"] = "-createdAt";
		var url = `admin/products?`;
		for (var key in data) {
			console.log({ key, data: data[key] });
			url += key + "=" + data[key] + "&";
		}
		axios
			.get(url)
			.then((res) => {
				if (res.status === 200 || res.status === 201) {
					dispatch(fetchProductsSuccess(res.data.data, res.data.total));
				} else {
					dispatch(fetchProductsFail());
				}
			})
			.catch((err) => {
				dispatch(fetchProductsFail());
			});
	};
};

const createProductStart = () => ({
	type: actionTypes.CREATE_PRODUCTS_START,
});

const createProductSuccess = (data) => ({
	type: actionTypes.CREATE_PRODUCTS_SUCCESS,
	product: data,
});

const createProductFail = () => ({
	type: actionTypes.CREATE_PRODUCTS_FAIL,
});

export const createProduct = (data, callback) => {
	return (dispatch) => {
		dispatch(createProductStart());

		axios
			.post("admin/products", data)
			.then((res) => {
				if (res.status === 200) {
					dispatch(createProductSuccess(res.data.data, res.data.total));
					dispatch({
						type: actionTypes.SUCCESS_MSG,
						message: res.data.message,
					});
					callback && callback();
				} else {
					dispatch(createProductFail());
					dispatch({ type: actionTypes.ERORR_MSG, message: res.data.message });
				}
			})
			.catch((err) => {
				dispatch(createProductFail());
				dispatch({
					type: actionTypes.ERORR_MSG,
					message: err.response.data.message,
				});
			});
	};
};

const fetchProductStart = () => ({
	type: actionTypes.FETCH_PRODUCT_START,
});

const fetchProductSuccess = (data, total) => ({
	type: actionTypes.FETCH_PRODUCT_SUCCESS,
	product: data,
});

const fetchProductFail = () => ({
	type: actionTypes.FETCH_PRODUCT_FAIL,
});

export const fetchProduct = (productId) => {
	return (dispatch) => {
		dispatch(fetchProductStart());

		axios
			.get(`admin/products/${productId}`)
			.then((res) => {
				if (res.status === 200 || res.status === 201) {
					dispatch(fetchProductSuccess(res.data.data));
				} else {
					// dispatch(fetchProductFail());
				}
			})
			.catch((err) => {
				dispatch(fetchProductFail());
			});
	};
};

const updateProductStart = () => ({
	type: actionTypes.UPDATE_PRODUCT_START,
});

const updateProductSuccess = (data) => ({
	type: actionTypes.UPDATE_PRODUCT_SUCCESS,
	product: data,
});

const updateCatgeoryProductSuccess = (data) => ({
	type: actionTypes.UPDATE_PRODUCT_CATEGORY,
	product: data,
});

const updateProductFail = () => ({
	type: actionTypes.UPDATE_PRODUCT_FAIL,
});

export const updateProduct = (
	productId,
	data,
	callback,
	sortProduct = false,
) => {
	return (dispatch) => {
		dispatch(updateProductStart());

		axios
			.patch(`admin/products/${productId}`, data)
			.then((res) => {
				if (res.status === 200) {
					dispatch(updateCatgeoryProductSuccess(res.data.data));
					dispatch(updateProductSuccess(res.data.data));

					dispatch({
						type: actionTypes.SUCCESS_MSG,
						message: res.data.message,
					});

					if (sortProduct) {
						dispatch({
							type: actionTypes.UPDATE_SORT_PRODUCTS,
							product: res.data.data,
						});
					}
					callback && callback();
				} else {
					dispatch(updateProductFail());
					dispatch({ type: actionTypes.ERORR_MSG, message: res.data.message });
				}
			})
			.catch((err) => {
				dispatch(updateProductFail());
				dispatch({
					type: actionTypes.ERORR_MSG,
					message: err.response ? err.response.data.message : err,
				});
			});
	};
};

const sortProductStart = () => ({
	type: actionTypes.SORT_PRODUCT_START,
});

const sortProductSuccess = (data, total) => ({
	type: actionTypes.SORT_PRODUCT_SUCCESS,
	product: data,
});

const sortProductFail = () => ({
	type: actionTypes.SORT_PRODUCT_FAIL,
});

export const sortProduct = (data, callback) => {
	return (dispatch) => {
		dispatch(sortProductStart());

		axiosJson
			.post(`admin/products/sort`, data)
			.then((res) => {
				if (res.status === 200) {
					dispatch(sortProductSuccess(res.data.data));
					dispatch({
						type: actionTypes.SUCCESS_MSG,
						message: res.data.message,
					});

					callback && callback();
				} else {
					dispatch(sortProductFail());
					dispatch({ type: actionTypes.ERORR_MSG, message: res.data.message });
				}
			})
			.catch((err) => {
				dispatch(sortProductFail());
				dispatch({
					type: actionTypes.ERORR_MSG,
					message: err.response ? err.response.data.message : err,
				});
			});
	};
};

const uploadProductsStart = () => ({
	type: actionTypes.UPLOAD_PRODUCTS_START,
});

const uploadProductsSuccess = (data, total) => ({
	type: actionTypes.UPLOAD_PRODUCTS_SUCCESS,
	product: data,
});

const uploadProductsFail = () => ({
	type: actionTypes.UPLOAD_PRODUCTS_FAIL,
});

export const uploadProducts = (data, callback) => {
	return (dispatch) => {
		dispatch(uploadProductsStart());

		axios
			.post(`admin/products/create-products`, data)
			.then((res) => {
				if (res.status === 200) {
					dispatch(uploadProductsSuccess(res.data.data));
					dispatch({
						type: actionTypes.SUCCESS_MSG,
						message: res.data.message,
					});

					callback && callback();
				} else {
					dispatch(uploadProductsFail());
					dispatch({ type: actionTypes.ERORR_MSG, message: res.data.message });
				}
			})
			.catch((err) => {
				dispatch(uploadProductsFail());
				dispatch({
					type: actionTypes.ERORR_MSG,
					message: err.response ? err.response.data.message : err,
				});
			});
	};
};

const changeProductsPricesStart = () => ({
	type: actionTypes.CHANGE_PRODUCTS_PRICES_START,
});

const changeProductsPricesSuccess = (data, total) => ({
	type: actionTypes.CHANGE_PRODUCTS_PRICES_SUCCESS,
	product: data,
});

const changeProductsPricesFail = () => ({
	type: actionTypes.CHANGE_PRODUCTS_PRICES_FAIL,
});

export const changeProductsPrices = (data, callback) => {
	return (dispatch) => {
		dispatch(changeProductsPricesStart());

		axios
			.post(`admin/products/change-prices`, data)
			.then((res) => {
				if (res.status === 200) {
					dispatch(changeProductsPricesSuccess(res.data.data));
					dispatch({
						type: actionTypes.SUCCESS_MSG,
						message: res.data.message,
					});

					callback && callback(res.data.data);
				} else {
					dispatch(changeProductsPricesFail());
					dispatch({ type: actionTypes.ERORR_MSG, message: res.data.message });
				}
			})
			.catch((err) => {
				dispatch(changeProductsPricesFail());
				dispatch({
					type: actionTypes.ERORR_MSG,
					message: err.response ? err.response.data.message : err,
				});
			});
	};
};

const fetchUpdatedProductsStart = () => ({
	type: actionTypes.FETCH_UPDATED_PRODUCTS_PRODUCTS_PRICES_START,
});

const fetchUpdatedProductsSuccess = (data, total) => ({
	type: actionTypes.FETCH_UPDATED_PRODUCTS_PRODUCTS_PRICES_SUCCESS,
	data,
	total,
});

const fetchUpdatedProductsFail = () => ({
	type: actionTypes.FETCH_UPDATED_PRODUCTS_PRODUCTS_PRICES_FAIL,
});

export const fetchUpdatedProducts = (data = {}) => {
	return (dispatch) => {
		dispatch(fetchUpdatedProductsStart());
		data["sort"] = "-createdAt";
		var url = `admin/products/updated-products?`;
		for (var key in data) {
			url += key + "=" + data[key] + "&";
		}
		axios
			.get(url)
			.then((res) => {
				if (res.status === 200) {
					dispatch(fetchUpdatedProductsSuccess(res.data.data, res.data.total));
					dispatch({
						type: actionTypes.SUCCESS_MSG,
						message: res.data.message,
					});
				} else {
					dispatch(fetchUpdatedProductsFail());
					dispatch({ type: actionTypes.ERORR_MSG, message: res.data.message });
				}
			})
			.catch((err) => {
				dispatch(fetchUpdatedProductsFail());
				dispatch({
					type: actionTypes.ERORR_MSG,
					message: err.response ? err.response.data.message : err,
				});
			});
	};
};
