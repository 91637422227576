import axios from "../axios/axios-orders";
import * as actionTypes from "./actionTypes";

const getUserCartStart = () => ({
	type: actionTypes.GET_USER_CART_START,
});

const getUserCartSuccess = (data, total) => ({
	type: actionTypes.GET_USER_CART_SUCCESS,
	cart: data,
});

const getUserCartFail = () => ({
	type: actionTypes.GET_USER_CART_FAIL,
});

export const getUserCart = (userId, callback) => {
	return (dispatch) => {
		dispatch(getUserCartStart());

		axios
			.get(`admin/cart/${userId}`)
			.then((res) => {
				if (res.status === 200 || res.status === 201) {
					dispatch(getUserCartSuccess(res.data.data));
					callback && callback();
				} else {
					dispatch(getUserCartFail());
				}
			})
			.catch((err) => {
				dispatch(getUserCartFail());
			});
	};
};

const addToUserCartStart = () => ({
	type: actionTypes.ADD_TO_USER_CART_START,
});

const addToUserCartSuccess = (data, total) => ({
	type: actionTypes.ADD_TO_USER_CART_SUCCESS,
	cart: data,
});

const addToUserCartFail = () => ({
	type: actionTypes.ADD_TO_USER_CART_FAIL,
});

export const addToUserCart = (userId, data, callback) => {
	return (dispatch) => {
		dispatch(addToUserCartStart());

		axios
			.post(`admin/cart/${userId}/add`, data)
			.then((res) => {
				if (res.status === 200 || res.status === 201) {
					dispatch(addToUserCartSuccess(res.data.data));
					dispatch({
						type: actionTypes.SUCCESS_MSG,
						message: res.data.message,
					});
					callback && callback();
				} else {
					dispatch(addToUserCartFail());
					dispatch({
						type: actionTypes.ERORR_MSG,
						message: res.data.message || "add item fail",
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: actionTypes.ERORR_MSG,
					message: err.response?.data?.message || "add item fail",
				});
				dispatch(addToUserCartFail());
			});
	};
};

const deleteCartItemStart = () => ({
	type: actionTypes.DELETE_CART_ITEM_START,
});

const deleteCartItemSuccess = (data, total) => ({
	type: actionTypes.DELETE_CART_ITEM_SUCCESS,
	cart: data,
});

const deleteCartItemFail = () => ({
	type: actionTypes.DELETE_CART_ITEM_FAIL,
});

export const deleteCartItem = (cartId, userId, itemIndex, callback) => {
	return (dispatch) => {
		dispatch(deleteCartItemStart());

		axios
			.delete(`admin/cart/${cartId}/${userId}/${itemIndex}`)
			.then((res) => {
				if (res.status === 200 || res.status === 201) {
					console.log(res.data.data, "res.data.data");
					dispatch(deleteCartItemSuccess(res.data.data));
					dispatch({
						type: actionTypes.SUCCESS_MSG,
						message: res.data.message,
					});
					callback && callback();
				} else {
					dispatch({
						type: actionTypes.ERORR_MSG,
						message: res.data.message,
					});

					dispatch(deleteCartItemFail());
				}
			})
			.catch((err) => {
				dispatch({
					type: actionTypes.ERORR_MSG,
					message: err.response?.data?.message || "delete item fail",
				});
				dispatch(deleteCartItemFail());
			});
	};
};

const addCartAddressStart = () => ({
	type: actionTypes.ADD_CART_ADDRESS_START,
});

const addCartAddressSuccess = (data, total) => ({
	type: actionTypes.ADD_CART_ADDRESS_SUCCESS,
	cart: data,
});

const addCartAddressFail = () => ({
	type: actionTypes.ADD_CART_ADDRESS_FAIL,
});

export const addCartAddress = (userId, data, callback) => {
	return (dispatch) => {
		dispatch(addCartAddressStart());

		axios
			.post(`admin/cart/${userId}/address`, data)
			.then((res) => {
				if (res.status === 200 || res.status === 201) {
					console.log(res.data.data, "res.data.data");
					dispatch(addCartAddressSuccess(res.data.data));
					dispatch({
						type: actionTypes.SUCCESS_MSG,
						message: res.data.message,
					});
					callback && callback();
				} else {
					dispatch({
						type: actionTypes.ERORR_MSG,
						message: res.data.message,
					});

					dispatch(addCartAddressFail());
				}
			})
			.catch((err) => {
				dispatch({
					type: actionTypes.ERORR_MSG,
					message: err.response?.data?.message || "delete item fail",
				});
				dispatch(addCartAddressFail());
			});
	};
};
