import axios from "../axios/axios-orders";
import * as actionTypes from "./actionTypes";

const fetchCouponsStart = () => ({
	type: actionTypes.FETCH_COUPONS_START,
});

const fetchCouponsSuccess = (data, total) => ({
	type: actionTypes.FETCH_COUPONS_SUCCESS,
	coupons: data,
	total: total,
});

const fetchCouponsFail = () => ({
	type: actionTypes.FETCH_COUPONS_FAIL,
});

export const fetchCoupons = (data = {}) => {
	return (dispatch) => {
		dispatch(fetchCouponsStart());
		data["sort"] = "-createdAt";
		var url = `admin/coupons?`;
		for (var key in data) {
			url += key + "=" + data[key] + "&";
		}
		axios
			.get(url)
			.then((res) => {
				if (res.status === 200 || res.status === 201) {
					dispatch(fetchCouponsSuccess(res.data.data, res.data.total));
					dispatch(fetchCouponsFail());
				}
			})
			.catch((err) => {
				dispatch(fetchCouponsFail());
			});
	};
};

const createCouponStart = () => ({
	type: actionTypes.CREATE_COUPON_START,
});

const createCouponSuccess = (data) => ({
	type: actionTypes.CREATE_COUPON_SUCCESS,
	coupon: data,
});

const createCouponFail = () => ({
	type: actionTypes.CREATE_COUPON_FAIL,
});

export const createCoupon = (data, callback) => {
	return (dispatch) => {
		dispatch(createCouponStart());

		axios
			.post("admin/coupons", data)
			.then((res) => {
				if (res.status === 200) {
					dispatch(createCouponSuccess(res.data.data, res.data.total));
					dispatch({
						type: actionTypes.SUCCESS_MSG,
						message: res.data.message,
					});
					callback && callback();
				} else {
					dispatch(createCouponFail());
					dispatch({ type: actionTypes.ERORR_MSG, message: res.data.message });
				}
			})
			.catch((err) => {
				dispatch(createCouponFail());
				dispatch({
					type: actionTypes.ERORR_MSG,
					message: ((err.response || {}).data || {}).message || "create fail",
				});
			});
	};
};

const updateCouponStart = () => ({
	type: actionTypes.UPDATE_COUPON_START,
});

const updateCouponSuccess = (data, total) => ({
	type: actionTypes.UPDATE_COUPON_SUCCESS,
	coupon: data,
});

const updateCouponFail = () => ({
	type: actionTypes.UPDATE_COUPON_FAIL,
});

export const updateCoupon = (couponId, data, callback) => {
	return (dispatch) => {
		dispatch(updateCouponStart());

		axios
			.patch(`admin/coupons/${couponId}`, data)
			.then((res) => {
				if (res.status === 200) {
					dispatch(updateCouponSuccess(res.data.data));
					dispatch({
						type: actionTypes.SUCCESS_MSG,
						message: res.data.message,
					});

					callback && callback();
				} else {
					dispatch(updateCouponFail());
					dispatch({ type: actionTypes.ERORR_MSG, message: res.data.message });
				}
			})
			.catch((err) => {
				dispatch(updateCouponFail());
				dispatch({
					type: actionTypes.ERORR_MSG,
					message: err.response ? err.response.data.message : err,
				});
			});
	};
};
