import axios from "../axios/axios-orders";
import * as actionTypes from "./actionTypes";

const fetchAreassStart = () => ({
	type: actionTypes.FETCH_AREAS_START,
});

const fetchAreassSuccess = (data, total) => ({
	type: actionTypes.FETCH_AREAS_SUCCESS,
	areas: data,
	total: total,
});

const fetchAreassFail = () => ({
	type: actionTypes.FETCH_AREAS_FAIL,
});

export const fetchAreas = (data = {}) => {
	return (dispatch) => {
		dispatch(fetchAreassStart());
		data["sort"] = "-createdAt";
		var url = `admin/areas?`;
		for (var key in data) {
			url += key + "=" + data[key] + "&";
		}
		axios
			.get(url)
			.then((res) => {
				if (res.status === 200 || res.status === 201) {
					dispatch(fetchAreassSuccess(res.data.data, res.data.total));
					dispatch(fetchAreassFail());
				}
			})
			.catch((err) => {
				dispatch(fetchAreassFail());
			});
	};
};

const createAreaStart = () => ({
	type: actionTypes.CREATE_AREA_START,
});

const createAreaSuccess = (data, total) => ({
	type: actionTypes.CREATE_AREA_SUCCESS,
	area: data,
});

const createAreaFail = () => ({
	type: actionTypes.CREATE_AREA_FAIL,
});

export const createArea = (data, callback) => {
	return (dispatch) => {
		dispatch(createAreaStart());

		axios
			.post(`admin/areas`, data)
			.then((res) => {
				if (res.status === 200) {
					dispatch(createAreaSuccess(res.data.data));
					dispatch({
						type: actionTypes.SUCCESS_MSG,
						message: res.data.message,
					});

					callback && callback();
				} else {
					dispatch(createAreaFail());
					dispatch({ type: actionTypes.ERORR_MSG, message: res.data.message });
				}
			})
			.catch((err) => {
				dispatch(createAreaFail());
				dispatch({
					type: actionTypes.ERORR_MSG,
					message: err.response ? err.response.data.message : err,
				});
			});
	};
};

const updateAreaStart = () => ({
	type: actionTypes.UPDATE_AREA_START,
});

const updateAreaSuccess = (data, total) => ({
	type: actionTypes.UPDATE_AREA_SUCCESS,
	area: data,
});

const updateAreaFail = () => ({
	type: actionTypes.UPDATE_AREA_FAIL,
});

export const updateArea = (areaId, data, callback) => {
	return (dispatch) => {
		dispatch(updateAreaStart());

		axios
			.patch(`admin/areas/${areaId}`, data)
			.then((res) => {
				if (res.status === 200 || res.status === 201) {
					dispatch(updateAreaSuccess(res.data.data));
					callback && callback();
				} else {
					dispatch(updateAreaFail());
				}
			})
			.catch((err) => {
				dispatch(updateAreaFail());
			});
	};
};
