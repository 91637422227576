import React, { useState } from "react";
import AppRouter from "./AppRouter";
import Alert from "../../components/Alert/Alert";
import SideBar from "../../components/SideBar";
import Header from "../../components/Header";

import "./App.css";
import "antd/dist/antd.css";
import { Layout } from "antd";

const { Content, Footer } = Layout;
const App = (props) => {
	const [collapsed, setCollapsed] = useState(false);

	const toggle = () => {
		setCollapsed(!collapsed);
	};
	const { url } = props.match || {};

	return (
		<Layout>
			<SideBar collapsed={collapsed} url={url} />
			<Layout className="site-layout">
				<Alert />
				<Header toggle={toggle} collapsed={collapsed} />
				<Content
					className="site-layout-background"
					style={{
						margin: "24px 16px",
						padding: 24,
						minHeight: "100vh",
					}}>
					<AppRouter {...props} />
				</Content>
				<Footer
					style={{
						textAlign: "center",
					}}>
					Labiba 2021 Created by Ant UED
				</Footer>
			</Layout>
		</Layout>
	);
};
export default App;
