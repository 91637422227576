import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { Spin } from "antd";
import UpdatedProducts from "../updatedPrdoucts";

const Orders = React.lazy(() => import("../Orders"));
const Products = React.lazy(() => import("../Products"));
const Categories = React.lazy(() => import("../Categories"));
const Category = React.lazy(() => import("../Categories/Category"));
const Roles = React.lazy(() => import("../Roles"));
const Users = React.lazy(() => import("../Users"));
const Order = React.lazy(() => import("../OrderDetails"));
const Maps = React.lazy(() => import("../../components/maps"));
const Areas = React.lazy(() => import("../Areas"));
const Marker = React.lazy(() => import("../../components/maps/markerMaps"));
const Dashboard = React.lazy(() => import("../Dashboard"));
const Settings = React.lazy(() => import("../Settings"));
const Coupons = React.lazy(() => import("../Coupons"));
const UploadProducts = React.lazy(() => import("../UplodaProducts"));
const Product = React.lazy(() => import("../Products/Product"));

const style = {
	textAlign: "center",
	background: "#f1f3f6",
	padding: "30% 50%",
	height: "100vh",
};

// const Option = SelectOption;

class AppRouter extends React.Component {
	render() {
		const { url } = this.props.match;

		return (
			<Switch>
				<Route
					exact
					path={`${url}/updated-products`}
					render={() => (
						<Suspense
							fallback={
								<div style={style}>
									<Spin size="large" />
								</div>
							}>
							<UpdatedProducts {...this.props} />
						</Suspense>
					)}
				/>
				<Route
					exact
					path={`${url}/upload-products`}
					render={() => (
						<Suspense
							fallback={
								<div style={style}>
									<Spin size="large" />
								</div>
							}>
							<UploadProducts {...this.props} />
						</Suspense>
					)}
				/>

				<Route
					exact
					path={`${url}/products/product/:product_id`}
					render={() => (
						<Suspense
							fallback={
								<div style={style}>
									<Spin size="large" />
								</div>
							}>
							<Product {...this.props} />
						</Suspense>
					)}
				/>
				<Route
					exact
					path={`${url}/`}
					render={() => (
						<Suspense
							fallback={
								<div style={style}>
									<Spin size="large" />
								</div>
							}>
							<Dashboard {...this.props} />
						</Suspense>
					)}
				/>

				<Route
					exact
					path={`${url}/coupons`}
					render={() => (
						<Suspense
							fallback={
								<div style={style}>
									<Spin size="large" />
								</div>
							}>
							<Coupons {...this.props} />
						</Suspense>
					)}
				/>

				<Route
					exact
					path={`${url}/settings`}
					render={() => (
						<Suspense
							fallback={
								<div style={style}>
									<Spin size="large" />
								</div>
							}>
							<Settings {...this.props} />
						</Suspense>
					)}
				/>
				<Route
					exact
					path={`${url}/marker`}
					render={() => (
						<Suspense
							fallback={
								<div style={style}>
									<Spin size="large" />
								</div>
							}>
							<Marker {...this.props} />
						</Suspense>
					)}
				/>
				<Route
					exact
					path={`${url}/map`}
					render={() => (
						<Suspense
							fallback={
								<div style={style}>
									<Spin size="large" />
								</div>
							}>
							<Maps {...this.props} />
						</Suspense>
					)}
				/>
				<Route
					exact
					path={`${url}/areas`}
					render={() => (
						<Suspense
							fallback={
								<div style={style}>
									<Spin size="large" />
								</div>
							}>
							<Areas {...this.props} />
						</Suspense>
					)}
				/>
				<Route
					exact
					path={`${url}/orderDetails/:order_id`}
					render={() => (
						<Suspense
							fallback={
								<div style={style}>
									<Spin size="large" />
								</div>
							}>
							<Order {...this.props} />
						</Suspense>
					)}
				/>
				<Route
					exact
					path={`${url}/users`}
					render={() => (
						<Suspense
							fallback={
								<div style={style}>
									<Spin size="large" />
								</div>
							}>
							<Users />
						</Suspense>
					)}
				/>
				<Route
					exact
					path={`${url}/roles`}
					render={() => (
						<Suspense
							fallback={
								<div style={style}>
									<Spin size="large" />
								</div>
							}>
							<Roles />
						</Suspense>
					)}
				/>

				<Route
					exact
					path={`${url}/categories`}
					render={() => (
						<Suspense
							fallback={
								<div style={style}>
									<Spin size="large" />
								</div>
							}>
							<Categories />
						</Suspense>
					)}
				/>

				<Route
					exact
					path={`${url}/category/:catgeory_id`}
					render={() => (
						<Suspense
							fallback={
								<div style={style}>
									<Spin size="large" />
								</div>
							}>
							<Category {...this.props} />
						</Suspense>
					)}
				/>

				<Route
					exact
					path={`${url}/orders`}
					render={() => (
						<Suspense
							fallback={
								<div style={style}>
									<Spin size="large" />
								</div>
							}>
							<Orders />
						</Suspense>
					)}
				/>
				<Route
					exact
					path={`${url}/products`}
					render={() => (
						<Suspense
							fallback={
								<div style={style}>
									<Spin size="large" />
								</div>
							}>
							<Products />
						</Suspense>
					)}
				/>
			</Switch>
		);
	}
}

export default AppRouter;
