import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
	coupons: [],
	coupon: {},
	total: 0,
	loading: false,
	options: {},
};

const updateData = (state, updatedCoupon) => {
	var coupons = [];

	state.coupons.forEach((coupon) => {
		if (updatedCoupon._id !== coupon._id) {
			coupons.push(coupon);
		} else if (!updatedCoupon.is_deleted) {
			coupons.push(updatedCoupon);
		}
	});

	return updateObject(state, {
		coupons: coupons,
		coupon: updatedCoupon,
		loading: false,
		total: state.total - 1,
	});
};

const couponReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_COUPONS_START:
			return updateObject(state, { loading: true });
		case actionTypes.FETCH_COUPONS_SUCCESS:
			return updateObject(state, {
				loading: false,
				coupons: action.coupons,
				total: action.total,
			});
		case actionTypes.FETCH_COUPONS_FAIL:
			return updateObject(state, { loading: false });
		case actionTypes.REINIT_COUPONS:
			return updateObject(state, initialState);

		case actionTypes.CREATE_COUPON_START:
			return updateObject(state, { loading: true });
		case actionTypes.CREATE_COUPON_SUCCESS:
			return updateObject(state, {
				coupon: action.coupon,
				coupons: [action.coupon, ...state.coupons],
				loading: false,
			});
		case actionTypes.CREATE_COUPON_FAIL:
			return updateObject(state, { loading: false });

		case actionTypes.UPDATE_COUPON_START:
			return updateObject(state, { loading: true });
		case actionTypes.UPDATE_COUPON_SUCCESS:
			return updateData(state, action.coupon);
		case actionTypes.UPDATE_COUPON_FAIL:
			return updateObject(state, { loading: false });

		default:
			return state;
	}
};

export default couponReducer;
