import React from "react";
import { Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import style from "./Alert.module.css";

const options = {
  position: "top right",
  timeout: 5000,
  offset: "30px",
  transition: "scale",
};

class Alert extends React.Component {
  render() {
    return (
      <AlertProvider
        className={style.position}
        template={AlertTemplate}
        {...options}
      >
        {this.props.children}
      </AlertProvider>
    );
  }
}

export default Alert;
