import axios from "../axios/axios-orders";
import * as actionTypes from "./actionTypes";
import { store } from "../store";

const getSortProductsStart = () => ({
	type: actionTypes.GET_SORTED_PRODUCTS_START,
});

const getSortProductsSuccess = (data, total) => ({
	type: actionTypes.GET_SORTED_PRODUCTS_SUCCESS,
	sortProducts: data,
});

const getSortProductsFail = () => ({
	type: actionTypes.GET_SORTED_PRODUCTS_FAIL,
});

export const getSortProducts = (data, callback) => {
	return (dispatch) => {
		dispatch(getSortProductsStart());
		data["sort"] = "-sort";
		var url = `admin/sort-products?`;
		for (var key in data) {
			url += key + "=" + data[key] + "&";
		}
		axios
			.get(url)
			.then((res) => {
				if (res.status === 200 || res.status === 201) {
					dispatch(getSortProductsSuccess(res.data.data));
					callback && callback();
				} else {
					dispatch(getSortProductsFail());
				}
			})
			.catch((err) => {
				dispatch(getSortProductsFail());
			});
	};
};

const sortProductsStart = () => ({
	type: actionTypes.SORT_PRODUCTS_START,
});

const sortProductsSuccess = (data) => ({
	type: actionTypes.SORT_PRODUCTS_SUCCESS,
	sortedProducts: data,
});

const sortProductsFail = () => ({
	type: actionTypes.SORT_PRODUCTS_FAIL,
});

export const sortingProducts = (data, index, callback, sortedProducts) => {
	return (dispatch) => {
		dispatch(sortProductsStart());

		if (!data) {
			data = moveToTop(index);
			sortedProducts = data.sortedProducts;
			data = data.sortData;
		}
		axios
			.post(`admin/sort-products/sort`, data)
			.then((res) => {
				if (res.status === 200) {
					dispatch(sortProductsSuccess(sortedProducts));
					dispatch({
						type: actionTypes.SUCCESS_MSG,
						message: res.data.message,
					});

					callback && callback();
				} else {
					dispatch(sortProductsFail());
					dispatch({ type: actionTypes.ERORR_MSG, message: res.data.message });
				}
			})
			.catch((err) => {
				dispatch(sortProductsFail());
				dispatch({
					type: actionTypes.ERORR_MSG,
					message: err.response ? err.response.data.message : err,
				});
			});
	};
};

const moveToTop = (index) => {
	let currentProducts = store.getState()?.SortProducts.sortProducts || [];
	currentProducts = [...currentProducts].sort((a, b) => a.sort - b.sort);
	let sortedProducts = [];
	let sortData = {};
	let counter = 2;
	for (let i = 0; i < currentProducts.length; i++) {
		const product = currentProducts[i];
		if (product._id === currentProducts[index]._id) {
			sortData[product._id] = 1;
			sortedProducts.unshift(product);
		} else {
			sortData[product._id] = ++counter;
			sortedProducts.push(product);
		}
	}

	return { sortData, sortedProducts };
};
