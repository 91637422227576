import axios from "../axios/axios-orders";
import * as actionTypes from "./actionTypes";

const fetchOrderssStart = () => ({
	type: actionTypes.FETCH_ORDERS_START,
});

const fetchOrderssSuccess = (data, total) => ({
	type: actionTypes.FETCH_ORDERS_SUCCESS,
	orders: data,
	total: total,
});

const fetchOrderssFail = () => ({
	type: actionTypes.FETCH_ORDERS_FAIL,
});

export const fetchOrders = (data = {}) => {
	return (dispatch) => {
		dispatch(fetchOrderssStart());
		data["sort"] = "-createdAt";
		var url = `admin/orders?`;
		for (var key in data) {
			url += key + "=" + data[key] + "&";
		}
		axios
			.get(url)
			.then((res) => {
				if (res.status === 200 || res.status === 201) {
					dispatch(fetchOrderssSuccess(res.data.data, res.data.total));
					dispatch(fetchOrderssFail());
				}
			})
			.catch((err) => {
				dispatch(fetchOrderssFail());
			});
	};
};

const updateOrderStart = () => ({
	type: actionTypes.UPDATE_ORDER_START,
});

const updateOrderSuccess = (data, total) => ({
	type: actionTypes.UPDATE_ORDER_SUCCESS,
	order: data,
});

const updateOrderFail = () => ({
	type: actionTypes.UPDATE_ORDER_FAIL,
});

export const updateOrder = (orderId, data, callback) => {
	return (dispatch) => {
		dispatch(updateOrderStart());

		axios
			.patch(`admin/orders/${orderId}`, data)
			.then((res) => {
				if (res.status === 200) {
					console.log({ order: res.data.data });
					dispatch(updateOrderSuccess(res.data.data));
					dispatch({
						type: actionTypes.SUCCESS_MSG,
						message: res.data.message,
					});

					callback && callback();
				} else {
					dispatch(updateOrderFail());
					dispatch({ type: actionTypes.ERORR_MSG, message: res.data.message });
				}
			})
			.catch((err) => {
				dispatch(updateOrderFail());
				dispatch({
					type: actionTypes.ERORR_MSG,
					message: err.response ? err.response.data.message : err,
				});
			});
	};
};

const fetchOrderDetailsStart = () => ({
	type: actionTypes.FETCH_ORDER_START,
});

const fetchOrderDetailsSuccess = (data, total) => ({
	type: actionTypes.FETCH_ORDER_SUCCESS,
	order: data,
});

const fetchOrderDetailsFail = () => ({
	type: actionTypes.FETCH_ORDER_FAIL,
});

export const fetchOrderDetails = (orderId) => {
	return (dispatch) => {
		dispatch(fetchOrderDetailsStart());

		axios
			.get(`admin/orders/${orderId}`)
			.then((res) => {
				if (res.status === 200 || res.status === 201) {
					dispatch(fetchOrderDetailsSuccess(res.data.data));
					dispatch(fetchOrderDetailsFail());
				}
			})
			.catch((err) => {
				dispatch(fetchOrderDetailsFail());
			});
	};
};

const createOrderShipmentStart = () => ({
	type: actionTypes.CREATE_ORDER_SHIPMENT_START,
});

const createOrderShipmentSuccess = (data, total) => ({
	type: actionTypes.CREATE_ORDER_SHIPMENT_SUCCESS,
	order: data,
});

const createOrderShipmentFail = () => ({
	type: actionTypes.CREATE_ORDER_SHIPMENT_FAIL,
});

export const createOrderShipment = (orderId) => {
	return (dispatch) => {
		dispatch(createOrderShipmentStart());

		axios
			.get(`admin/orders/${orderId}`)
			.then((res) => {
				if (res.status === 200 || res.status === 201) {
					dispatch(createOrderShipmentSuccess(res.data.data));
					dispatch(createOrderShipmentFail());
				}
			})
			.catch((err) => {
				dispatch(createOrderShipmentFail());
			});
	};
};

const createUserOrderStart = () => ({
	type: actionTypes.CREATE_USER_ORDER_START,
});

const createUserOrderSuccess = (data, total) => ({
	type: actionTypes.CREATE_USER_ORDER_SUCCESS,
	order: data,
});

const createUserOrderFail = () => ({
	type: actionTypes.CREATE_USER_ORDER_FAIL,
});

export const createUserOrder = (cartId, userId, callback) => {
	return (dispatch) => {
		dispatch(createUserOrderStart());

		axios
			.post(`admin/orders/${cartId}/${userId}`)
			.then((res) => {
				if (res.status === 200) {
					dispatch(createUserOrderSuccess(res.data.data));

					dispatch({
						type: actionTypes.SUCCESS_MSG,
						message: res.data.message,
					});
					callback && callback();
				} else {
					dispatch({
						type: actionTypes.ERORR_MSG,
						message: "create order fail",
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: actionTypes.ERORR_MSG,
					message: "create order fail",
				});
				dispatch(createUserOrderFail());
			});
	};
};

const printOrderStart = () => ({
	type: actionTypes.PRINT_ORDER_START,
});

const printOrderSuccess = () => ({
	type: actionTypes.PRINT_ORDER_SUCCESS,
});

const printOrderFail = () => ({
	type: actionTypes.PRINT_ORDER_FAIL,
});

export const printOrder = (orderId) => {
	return (dispatch) => {
		dispatch(printOrderStart());

		axios
			.get(`admin/orders/${orderId}/print`)
			.then((res) => {
				if (res.status === 200 || res.status === 201) {
					dispatch(printOrderSuccess());
					dispatch({
						type: actionTypes.SUCCESS_MSG,
						message: "print successfully",
					});
					// console.log(res.data.data, "res.data.data");
					window.open(res.data.data, "_blank");
				}
			})
			.catch((err) => {
				dispatch(printOrderFail());
			});
	};
};
