import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
	areas: [],
	area: {},
	total: 0,
	loading: false,
	options: {},
};

const updateData = (state, updatedArea) => {
	var areas = [];

	state.areas.forEach((product) => {
		if (updatedArea._id !== product._id) {
			areas.push(product);
		} else if (!updatedArea.is_deleted) {
			areas.push(updatedArea);
		}
	});

	return updateObject(state, {
		areas: areas,
		product: updatedArea,
		loading: false,
		total: state.total - 1,
	});
};

const areasReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.REINIT_AREAS:
			return updateObject(state, initialState);
		case actionTypes.FETCH_AREAS_START:
			return updateObject(state, { loading: true });
		case actionTypes.FETCH_AREAS_SUCCESS:
			return updateObject(state, {
				loading: false,
				areas: action.areas,
				total: action.total,
			});
		case actionTypes.FETCH_AREAS_FAIL:
			return updateObject(state, { loading: false });

		case actionTypes.CREATE_AREA_START:
			return updateObject(state, { loading: true });
		case actionTypes.CREATE_AREA_SUCCESS:
			return updateObject(state, {
				area: action.area,
				areas: [action.area, ...state.areas],
				loading: false,
			});
		case actionTypes.CREATE_AREA_FAIL:
			return updateObject(state, { loading: false });

		case actionTypes.UPDATE_AREA_START:
			return updateObject(state, { loading: true });
		case actionTypes.UPDATE_AREA_SUCCESS:
			return updateData(state, action.area);
		case actionTypes.UPDATE_AREA_FAIL:
			return updateObject(state, { loading: false });

		default:
			return state;
	}
};

export default areasReducer;
