import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
	keyWords: [],
	total: 0,
	loading: false,
	options: {},
};

const keyWordsReducer = (state = initialState, action) => {
	switch (action.type) {
		// case actionTypes.REINIT_AREAS:
		// 	return updateObject(state, initialState);
		case actionTypes.FETCH_KEY_WORDS_START:
			return updateObject(state, { loading: true });
		case actionTypes.FETCH_KEY_WORDS_SUCCESS:
			return updateObject(state, {
				loading: false,
				keyWords: action.keyWords.map((ele) => {
					return ele.key_word;
				}),
				total: action.total,
			});
		case actionTypes.FETCH_KEY_WORDS_FAIL:
			return updateObject(state, { loading: false });

		default:
			return state;
	}
};

export default keyWordsReducer;
