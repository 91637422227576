import Products from "./products-reducer";
import Users from "./users-reducer";
import Categories from "./categories-reducer";
import Roles from "./roles-reducer";
import Configs from "./configs-reducer";
import Orders from "./orders-reducer";
import Areas from "./areas-reducer";
import Notification from "./notification-reducer";
import Cart from "./cart-reducer";
import Coupons from "./coupon-reducer";
import SortProducts from "./sortProducts-reducer";
import KeyWords from "./keyWords-reducer";

export default {
	Products,
	Users,
	Categories,
	Roles,
	Configs,
	Orders,
	Areas,
	Notification,
	Cart,
	Coupons,
	SortProducts,
	KeyWords,
};
