import React from "react";
import { Layout, Menu } from "antd";

import {
	UserOutlined,
	DropboxOutlined,
	GroupOutlined,
	IdcardOutlined,
	CodeSandboxOutlined,
	EnvironmentOutlined,
	SettingOutlined,
	GiftOutlined,
} from "@ant-design/icons";

import { Link } from "react-router-dom";

const { Sider } = Layout;
const logo = `/logo.png`;

const stripTrailingSlash = (str = "") => {
	if (str.substr(-1) === "/") {
		return str.substr(0, str.length - 1);
	}
	return str;
};

const SideBar = (props) => {
	const url = stripTrailingSlash(props.url);

	return (
		<Sider
			// style={{
			// 	overflow: "auto",
			// 	height: "100vh",
			// 	position: "fixed",
			// 	left: 0,
			// }}
			trigger={null}
			collapsible
			collapsed={props.collapsed}>
			<div className="logo">
				<Link to={`${url}/`}>
					<img
						alt="logo"
						style={{
							width: !props.collapsed ? 150 : 50,
							maxHeight: !props.collapsed ? 50 : 30,
							paddingBottom: 6,
						}}
						src={logo}
					/>
				</Link>
			</div>
			<Menu theme="dark" mode="inline">
				<Menu.Item key="1" icon={<CodeSandboxOutlined />}>
					<Link to={`${url}/orders`}>orders</Link>
				</Menu.Item>
				<Menu.Item key="3" icon={<DropboxOutlined />}>
					<Link to={`${url}/products`}>Products</Link>
				</Menu.Item>
				<Menu.Item key="4" icon={<GroupOutlined />}>
					<Link to={`${url}/categories`}>Categories</Link>
				</Menu.Item>
				<Menu.Item key="8" icon={<GiftOutlined />}>
					<Link to={`${url}/Coupons`}>Coupons</Link>
				</Menu.Item>
				<Menu.Item key="6" icon={<EnvironmentOutlined />}>
					<Link to={`${url}/areas`}>Areas</Link>
				</Menu.Item>
				<Menu.Item key="2" icon={<UserOutlined />}>
					<Link to={`${url}/users`}>users</Link>
				</Menu.Item>
				<Menu.Item key="5" icon={<IdcardOutlined />}>
					<Link to={`${url}/roles`}>Roles</Link>
				</Menu.Item>

				<Menu.Item key="9" icon={<DropboxOutlined />}>
					<Link to={`${url}/upload-products`}>Upload products</Link>
				</Menu.Item>

				<Menu.Item key="10" icon={<DropboxOutlined />}>
					<Link to={`${url}/updated-products`}>Updated products</Link>
				</Menu.Item>

				<Menu.Item key="7" icon={<SettingOutlined />}>
					<Link to={`${url}/settings`}>Settings</Link>
				</Menu.Item>
			</Menu>
		</Sider>
	);
};

export default SideBar;
