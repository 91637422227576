import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initState = {
	message: null,
	type: "",
};

const notificationReducer = (state = initState, action) => {
	switch (action.type) {
		case actionTypes.ERORR_MSG:
			return updateObject(state, {
				message: action.message,
				type: "error",
			});
		case actionTypes.SUCCESS_MSG:
			return updateObject(state, { message: action.message, type: "success" });
		case actionTypes.REINIT_MSG:
			return updateObject(state, { message: null, type: "" });
		default:
			return state;
	}
};
export default notificationReducer;
